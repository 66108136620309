import React, { useState } from "react";
import Fa from "./FontAwesome";
// import { useQuery } from "@tanstack/react-query";
// import { BugReportsEntity } from "../../../backend/src/shared/entity";
import sql from "../common/sql";
import { useUser } from "../hooks/useUser";

interface UserInfo {
  userId: string;
  username: string;
  email: string;
}

interface BugReportProps {
  userInfo: UserInfo;
  page: string;
  language: string;
}

const bugCategories = [
  { id: 'ui', name: 'User Interface' },
  { id: 'performance', name: 'Performance' },
  { id: 'crash', name: 'Crashes' },
  { id: 'security', name: 'Security' },
  { id: 'other', name: 'Other' }
];

const BugReport: React.FC<BugReportProps> = ({ page, language }) => {
  const [bugDescription, setBugDescription] = useState("");
  const [bugCategory, setBugCategory] = useState("ui");
  const [showForm, setShowForm] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const { user } = useUser();

  // const { isLoading, data } = useQuery({
  //   queryKey: ["BugReportsEntity"],
  //   queryFn: () => sql.getEntity("dbo.BugReports", 0) as Promise<BugReportsEntity>,
  // });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "category") {
      setBugCategory(value);
    } else if (name === "description") {
      setBugDescription(value);
    }
  };

  const handleSubmit = async () => {
    const { Id: userId, Name: username, Email: email } = user.account!;
    const bugReportData = {
      category: bugCategory,
      description: bugDescription,
      userId,
      username,
      email,
      page,
      language,
      timestamp: new Date().toISOString(),
      browserInfo: navigator.userAgent,
      screenSize: `${window.innerWidth} x ${window.innerHeight}`,
    };

    try {
      await sql.saveEntity("dbo.BugReports", bugReportData);
      setShowForm(false);
      setBugDescription("");
      setBugCategory("ui");
      setSubmissionMessage("Thank you for your submission. We appreciate your help in improving our service!");
      setTimeout(() => setSubmissionMessage(""), 5000); // Clear the message after 5 seconds
    } catch (error) {
      console.error("Error saving bug report:", error);
      setSubmissionMessage("Failed to submit the bug report. Please try again.");
    }
  };

  return (
    <div className="fixed-top-right">
      <button onClick={() => setShowForm(!showForm)}>
        <Fa solid="bug" />
        {showForm ? '' : ''}
      </button>
      {showForm && (
        <div className="overlay">
          <div className="bug-modal-content">
            <button className="close-button" onClick={() => setShowForm(false)}>X</button>
            <h1>Report a bug!</h1>
            <br />
            <select
              className="form-control"
              value={bugCategory}
              onChange={handleChange}
              name="category"
            >
              {bugCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <br />
            <textarea
              className="form-control"
              value={bugDescription}
              onChange={handleChange}
              placeholder="Describe the bug you encountered..."
              rows={10}
              name="description"
            />
            <br />
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {submissionMessage && <div className="submission-message">{submissionMessage}</div>}
    </div>
  );
};

export default BugReport;
