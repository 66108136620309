import { SyntheticEvent, useState } from 'react';
import Form from './Form';
import Title from './Title';
import Button from './Button';

// change password component
const AccountPassword = () => {
    const [model, setModel] = useState({ password: '', newPassword: '', confirmPassword: '' });
    const labels = { password: 'Password', newPassword: 'New password', confirmPassword: 'Confirm password' };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setModel({ ...model, [name]: value });
    };
    const handleSubmit = (e: SyntheticEvent<Element, Event>) => {
        e.preventDefault();
        window.alert('Not implemented yet!');
    };
    return (
        <Form>
            <Title text='Schimbă parola' />
            <div className="form-floating mb-3">
                <input type="password" name="password" id="password" value={model.password} onChange={handleChange} placeholder={labels.password} />
                <label htmlFor="password">{labels.password}</label>
            </div>
            <div className="form-floating mb-3">
                <input type="password" name="newPassword" id="newPassword" value={model.newPassword} onChange={handleChange} placeholder={labels.newPassword} />
                <label htmlFor="newPassword">{labels.newPassword}</label>
            </div>
            <div className="form-floating mb-3">
                <input type="password" name="confirmPassword" id="confirmPassword" value={model.confirmPassword} onChange={handleChange} placeholder={labels.confirmPassword} />
                <label htmlFor="confirmPassword">{labels.confirmPassword}</label>
            </div>
            <Button onClick={handleSubmit} text='Schimbă parola' />
        </Form>
    );
}

export default AccountPassword