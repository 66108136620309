import React from 'react'
import { MentoringSessionEntity } from '../../../../backend/src/shared/entity';
import { useTranslate } from '../../common/lang';
import { localeDateTime } from '../../common/utils';

const MentoringSessionPrevious = ({ session = {} as MentoringSessionEntity }) => {
    const translate = useTranslate();
    return (
        <div className="alert alert-warning" role="alert">{translate("Ultima ta întâlnire a fost pe")} : {session?.Id ? (localeDateTime(session.DateProposal)) : "——"}</div>
    )
}

export default MentoringSessionPrevious