import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { useParams } from 'react-router-dom'
import sql from '../../common/sql';
import { UserEntity } from '../../../../backend/src/shared/entity';
import Loading from '../../components/Loading/Loading';
import ErrorBox from '../../components/ErrorBox';
import FaRole from '../../components/Icons/FaRole';
import { UserRole } from '../../common/user';
import Button from '../../components/Button';
import { useTranslate } from '../../common/lang';
import Back from '../../components/Back';
import { useUser } from '../../hooks/useUser';

type Roomate = { Id: number; IdUser: number };

const CoordinatorTeamRooms = () => {
  const { id, name } = useParams();
  const { user } = useUser();
  const [isSaving, setIsSaving] = React.useState(false);
  const [selected, setSelected] = React.useState<Array<Roomate>>();

  const translate = useTranslate();
  const roommates = useQuery({
    queryKey: ['roommatesByIdQuery', id],
    queryFn: () => sql.roommatesByIdQuery({ id }) as Promise<Array<Roomate>>
  });

  const users = useQuery({
    queryKey: ['coordinatorTeamQuery'],
    queryFn: () => sql.coordinatorTeamQuery({ idUser: user.account?.Id }) as Promise<Array<UserEntity>>,
    enabled: !!user.account?.Id
  });

  if (users.isLoading || roommates.isLoading || isSaving || roommates.isRefetching) return <Loading />
  if (users.isError || roommates.isError) return <ErrorBox>{users.error || roommates.error}</ErrorBox>
  if (roommates.data && roommates.data.length && !selected) setSelected(roommates.data);

  const handleSave = () => {
    setIsSaving(true);
    const idUser = users.data.find(u => u.Id === +id!)?.Id;
    const promises = (selected || []).filter(s => !s.Id).map(s => sql.saveEntity("dbo.ChatRoom", { Id: s.Id, IdUser1: idUser, IdUser2: s.IdUser }));
    promises.push(...roommates.data.filter(r => !selected?.some(s => s.IdUser === r.IdUser)).map(r => sql.deleteEntity("dbo.ChatRoom", r.Id)));
    Promise
      .all(promises)
      .then(() => [setSelected(undefined), roommates.refetch()])
      .finally(() => setIsSaving(false));
  }
  const hasChanges = () => selected?.some(s => !s.Id) || roommates.data.some(r => !selected?.some(s => s.IdUser === r.IdUser));

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <Back />
        <span className='h5'>{name}</span>
        <Button width='w-auto'
          color={`btn-primary ${hasChanges() || 'disabled'}`}
          onClick={e => [e.preventDefault(), handleSave()]}>
          {translate("Salvează")}
        </Button>
      </div>
      <hr />
      <ul className='nav flex-column'>
        {/* check / uncheck roomate for curent user, and push/remove from selected array */}
        {users.data?.filter(u => u.Id !== +id!).map(u => (
          <li key={u.Id}>
            <label className='py-2'>
              <input type='checkbox' defaultChecked={selected?.some(s => s.IdUser === u.Id)} onChange={e => {
                if (e.target.checked) setSelected([...(selected || []), { Id: 0, IdUser: u.Id }]);
                else setSelected(selected?.filter(s => s.IdUser !== u.Id));
              }} />&nbsp;&nbsp;
              <FaRole role={u.Role as UserRole} /> &nbsp; {u.Name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CoordinatorTeamRooms