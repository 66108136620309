import React from 'react'
import Title from '../Title'
import { useTranslate } from '../../common/lang'

const EuFunding = () => {
    const translate = useTranslate();
    return (
        <div>
            <Title text="EU Funding" />
            <p>
               {translate("Proiectul Mentor+ a primit finanțare de la Programul Erasmus+ al Uniunii Europene sub numărul acordului nr: 2021-2-BE05-KA220-YOU-000050037.")}
            </p>
            <p>
                {translate("Sprijinul Comisiei Europene pentru producerea acestei aplicații mobile și a publicațiilor din aceasta nu constituie o aprobare a conținutului care reflectă doar punctul de vedere al autorului (autorilor), iar Comisia nu poate fi făcută responsabilă pentru orice utilizare care poate fi făcută informatiile continute de acesta.")}
            </p>
            <p>
                <img src="/images/eu-funding.png" className='img-fluid' alt="" />
            </p>
        </div>
    )
}

export default EuFunding