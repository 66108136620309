import React from 'react'
import { UserRole } from '../../common/user'
import FaAdmin from './FaAdmin'
import FaCoordinator from './FaCoordinator'
import FaMentor from './FaMentor'
import FaMentee from './FaMentee'

const FaRole = ({ role = "Admin" as UserRole }) => {
  if (role === "Admin") return <FaAdmin />
  if (role === "Coordonator") return <FaCoordinator />
  if (role === "Mentor") return <FaMentor />
  return <FaMentee />
}
export default FaRole;