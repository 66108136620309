import React, { useState, useEffect } from "react";
import { moodStates } from "./moodStates";
import sql from "../../common/sql";
import { MoodSubmissionsEntity } from "../../../../backend/src/shared/entity";
import { useUser } from "../../hooks/useUser";
import { Labels, useTranslate } from "../../common/lang";

const MoodRing: React.FC = () => {
  const translate = useTranslate();
  const [moodValues, setMoodValues] = useState<number[]>(
    new Array(moodStates.length).fill(0)
  );
  const [model, setModel] = useState<MoodSubmissionsEntity>(
    {} as MoodSubmissionsEntity
  );
  const { user } = useUser();
  const [hasSubmittedToday, setHasSubmittedToday] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const checkSubmission = async () => {
      const result = await sql.checkTodaysSubmition({ NumeUser: user.name });
      // Check if the result is an empty array
      setHasSubmittedToday(Array.isArray(result) && result.length > 0);
      console.log("result", result);
      console.log("user", user.name);
    };

    checkSubmission();
  }, [user.name]);

  const handleSliderChange = (index: number, value: number) => {
    const updatedMoodValues = [...moodValues];
    updatedMoodValues[index] = value;
    setMoodValues(updatedMoodValues);
    setModel((prevModel) => ({
      ...prevModel,
      [`MoodValue${index + 1}`]: value,
    }));
    setErrorMessage(""); // Clear error message when user changes slider
  };

  const handleSave = async () => {
    // Check if all sliders have been moved from the initial value of 0
    const allSlidersMoved = moodValues.every((value) => value !== 0);
    if (!allSlidersMoved) {
      setErrorMessage(translate("Te rog asigură-te că ai selectat un răspuns pentru fiecare categorie."));
      return;
    }

    const updatedModel = {
      ...model,
      NumeUser: user.name,
      DataCompletare: new Date().toISOString(),
    };

    try {
      await sql.saveEntity("dbo.MoodSubmissions", updatedModel);
      alert(translate("Alegerile tale de stare au fost trimise cu succes!"));
      setHasSubmittedToday(true); // Update the submission status
    } catch (error) {
      console.error("Error saving mood submission:", error);
      alert(translate("Trimiterea alegerilor tale de stare a eșuat. Te rog încearcă din nou."));
    }
  };

  if (hasSubmittedToday) {
    return (
      <div className="container text-center">
        {" "}
        <p className="lead">
          {translate("Închiderea inelelor în fiecare zi te ajută să urmărești echilibrul între a te bucura de spontaneitate și a cântări grijile tale.")}
        </p>
        <p className="lead">
          {translate("Mulțumim că ți-ai împărtășit starea!")}
        </p>
      </div>
    );
  }
  return (
    <div className="container text-center">
      <p className="lead">
      {translate("Folosirea unui inel de stare pentru a ghida alegerile tale poate fi o modalitate jucăușă și creativă de a te conecta cu sentimentele tale.")}
      </p>
      <p className="lead">
      {translate("Este o metodă distractivă de a reflecta asupra modului în care te simți în acest moment.")}
      
      </p>
      <p className="lead">{translate("Te rog, nu uita să-ți faci alegerile astăzi!")}</p>
      {moodStates.map((moodState, index) => (
        <div key={moodState.label} className="mood-state-container">
          <h2 className="mood-label">{translate(moodState.label as Labels)}</h2>
          <p className="mood-option">
            {moodValues[index] === 0
              ? translate("Mută cursorul pentru a vedea mai multe răspunsuri")
              : translate(moodState.options[moodValues[index] - 1] as Labels)}
          </p>
          <div className="slider-container">
            <input
              type="range"
              className="styled-slider"
              min="0"
              max="5"
              value={moodValues[index]}
              onChange={(e) =>
                handleSliderChange(index, parseInt(e.target.value, 10))
              }
            />
            <div className="slider-ticks">
              {Array.from({ length: 6 }, (_, i) => (
                <span key={i} className="tick-mark"></span>
              ))}
            </div>
          </div>
        </div>
      ))}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      <button onClick={handleSave} className="btn btn-primary mt-3">
        {translate("Trimite alegerile")}
      </button>
    </div>
  );
};

export default MoodRing;
