import React, { useEffect, useState, useRef } from 'react';

interface BeforeInstallPromptEvent extends Event {
    prompt: () => Promise<void>;
    userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

const InstallPage: React.FC = () => {
    const [showInstallButton, setShowInstallButton] = useState(false);
    const [showIOSInstructions, setShowIOSInstructions] = useState(false);
    const [modalImage, setModalImage] = useState<string | null>(null);
    const deferredPrompt = useRef<BeforeInstallPromptEvent | null>(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: any) => {
            e.preventDefault();
            deferredPrompt.current = e;
            setShowInstallButton(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        const userAgent = navigator.userAgent;
        const isIOS = /iPhone|iPad|iPod/.test(userAgent);
        const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator as any).standalone;
        if (isIOS && !isInStandaloneMode) {
            setShowIOSInstructions(true);
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then(registration => {
                    console.log('Service Worker Registered!', registration);
                })
                .catch(error => {
                    console.log('Service Worker Registration Failed', error);
                });
        }

        if (isInStandaloneMode) {
          window.location.href = 'https://mentor-plus.app'; // Redirect URL
      }

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt.current) {
            deferredPrompt.current.prompt();
            deferredPrompt.current.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                deferredPrompt.current = null;
                setShowInstallButton(false);
            });
        }
    };

    const openModal = (imgSrc: string) => {
        setModalImage(imgSrc);
    };

    const closeModal = () => {
        setModalImage(null);
    };

    return (
        <div>
          <img src="/images/mentor-logo.png" alt="Mentor Logo" style={{ width: '200px', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }} />
          <br />
            {showInstallButton && (
                <div className='text-center'>
                    <h1>Click the button below to install the Mentor+ app to your device</h1>
                    <br></br>
                    <button id="installButton" onClick={handleInstallClick}>Install App</button>
                </div>
            )}

            {showIOSInstructions && (
                <div id="iosInstallMessage" style={{
                    border: '1px solid #ccc',
                    padding: '10px',
                    marginTop: '20px',
                    borderRadius: '5px',
                    backgroundColor: '#f8f8f8',
                    display: 'block'
                }}>
                    <h1>To install the Mentor+ app on your iPhone: tap <img src="/images/share-icon.png" alt="Share icon" style={{ width: 24, height: 24 }} /> and then 'Add to Home Screen'</h1>
                    <p>Guide below:</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {['/images/install/Step1.jpg', '/images/install/Step2.jpg', '/images/install/Step3.jpg', '/images/install/Step4.jpg'].map(imgSrc => (
                            <div key={imgSrc} style={{ width: '48%', marginBottom: '10px' }}>
                                <img src={imgSrc} alt={`Step`} style={{ width: '100%', height: 'auto', cursor: 'pointer' }} onClick={() => openModal(imgSrc)} />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {modalImage && (
               <div style={{
                position: 'fixed',
                top: 0,
                left: 0,  // Corrected by adding the colon
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }} onClick={closeModal}>
                <img src={modalImage} alt="Enlarged step" style={{
                    maxWidth: '75%',
                    maxHeight: '75%',
                    cursor: 'pointer'
                }} onClick={(e) => e.stopPropagation()} />
            </div>
            
            )}
        </div>
    );
};

export default InstallPage;
