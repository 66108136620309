import { MentoringSessionEntity } from "../../../../backend/src/shared/entity";
import { useTranslate } from "../../common/lang";
import useCache from "../../hooks/useCache";
import { useUser } from "../../hooks/useUser";
import Form from "../Form";
import CalendarPicker from "./CalendarPicker";

const MentoringSessionDateLocationForm = ({ model = {} as MentoringSessionEntity, minDate = new Date(), maxDate = new Date(), handleSave = () => { }, handleCancel = () => { }, setModel = (model: MentoringSessionEntity) => { }, sessionIndex = 0 }) => {
    const { user } = useUser();
    const translate = useTranslate();
    const hasChanges = useCache(model);
    const isDisabled = () => hasChanges() || 'disabled';
    const linkOrAdressLabel = translate(model.Location === 1 ? "Link-ul sesiunii online" : "Adresa fizică");
    return (
        <Form onSubmit={handleSave}>
            <p className='text-center fs-5'>{sessionIndex ? `# ${sessionIndex} — ` : ``}{translate(model.Id ? 'Solicit modificare' : 'Data și locul')}</p>
            <CalendarPicker minDate={minDate} maxDate={maxDate} required date={model.DateProposal} setDate={d => setModel({ ...model, DateProposal: d })} />
            {user.role === 'Mentor' &&
                <>
                    <select className='form-control rounded-5 my-2' value={model.Location} onChange={(e) => setModel({ ...model, Location: +e.target.value })} required>
                        <option value={0}>{translate("— Selecție locație —")}</option>
                        <option value={1}>{translate("întâlnire online")}</option>
                        <option value={2}>{translate("întâlnire față în față")}</option>
                    </select>
                    {!!model.Location &&
                        <div className="form-floating">
                            <input type="text" className='form-control rounded-5' placeholder={linkOrAdressLabel} value={model.LinkOrAddress} onChange={(e) => setModel({ ...model, LinkOrAddress: e.target.value })} />
                            <label htmlFor="floatingInput">{linkOrAdressLabel}</label>
                        </div>
                    }
                </>
            }
            <button type='submit' className={`btn btn-primary w-100 mt-3 ${isDisabled()}`}>{translate('Salvează')}</button>
            <button className={`btn btn-secondary w-100 mt-3 mb-5`} onClick={e => [e.preventDefault(), handleCancel()]}>{translate('Anulează')}</button>
        </Form>
    );
}

export default MentoringSessionDateLocationForm;
