import { ChangeEvent, SyntheticEvent, useState } from "react";
import { RegisterInfo, UserRole, registerUser } from "../../common/user";
import LoginContainer from "../../components/LoginContainer";
import ErrorBox from "../../components/ErrorBox";
import { SqlError } from "../../common/sql";
import Fa from "../../components/FontAwesome";
import Button from "../../components/Button";
import { useTranslate } from "../../common/lang";
import Loading from "../../components/Loading/Loading";
import { useData } from "../../hooks/useData";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterPage = () => {
  const [model, setModel] = useState<RegisterInfo>({
    firstName: "",
    lastName: "",
    idCountry: 0,
    idPartner: 0,
    name: "",
    role: "" as UserRole,
    email: "",
    password: "",
    passwordRepeat: "",
    captcha: null,
  });
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<SqlError>();
  const [roles] = useState<UserRole[]>(["Coordonator", "Mentor", "Mentorat"]);
  const translate = useTranslate();
  const data = useData();

  if (data.isLoading || data.isRefetching) return <Loading />;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({ ...model, [e.target.name]: e.target.value }));
    setError(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    registerUser({ ...model, name: `${model.firstName} ${model.lastName}` })
      .then(() => {
        setCompleted(true);
      })
      .catch(setError);
  };
  const labels = {
    nume: translate("Nume"),
    prenume: translate("Prenume"),
    email: translate("Email"),
    parola: translate("Parolă"),
    confirmareParola: translate("Confirmare Parolă"),
    confirmareInregistrare: translate("Vei putea să te conectezi după ce îți validezi adresa de email ! <br /> Verifică căsuța de email <b>@email</b> și folosește link-ul de activare primit. Verifică atât în folderul <b>inbox</b> cât și în folderul <b>spam</b>.").replace("@email", model.email),
  }
  return (
    <LoginContainer onSubmit={handleSubmit}>
      {completed ? (
        <>
          <h5 className="card-title pt-5 my-4"><Fa light="user-check fa-xl me-2 text-success" />{translate('Cont creat cu succes !')}</h5>
          <p className="card-text" dangerouslySetInnerHTML={{ __html: labels.confirmareInregistrare }}></p>
          <Button to="/home" color="btn-outline-warning">
            <Fa duotone="circle-arrow-left fa-xl fa-fw" /> {translate("Închide")}
          </Button>
        </>
      ) : (
        <>
          <select className='form-control rounded-5 mb-3' value={model.role} onChange={(e) => setModel((model) => ({ ...model, role: e.target.value as UserRole }))}>
            <option value="">{translate("— Selecție rol —")}</option>
            {roles.map((role) => (
              <option key={role} value={role}>{translate(role)}</option>
            ))}
          </select>

          <select className='form-control rounded-5 mb-3' value={model.idCountry} onChange={(e) => setModel((model) => ({ ...model, idCountry: +e.target.value, idPartner: 0 }))}>
            <option value="">{translate("— Selecție țară —")}</option>
            {data.Country?.map((country) => (
              <option key={country.Id} value={country.Id}>{country.Name}</option>
            ))}
          </select>

          <select className='form-control rounded-5 mb-3' value={model.idPartner} onChange={(e) => setModel((model) => ({ ...model, idPartner: +e.target.value }))}>
            <option value="">{translate("- Selecție organizație (opțional) -")}</option>
            {data.Partner?.filter(p => p.IdCountry === model.idCountry).map((partner) => (
              <option key={partner.Id} value={partner.Id}>{partner.Name}</option>
            ))}
          </select>

          <div className="form-floating mb-3">
            <input type="text" name="firstName" value={model.firstName} onChange={handleChange} placeholder={labels.nume} />
            <label>{labels.nume}</label>
          </div>

          <div className="form-floating mb-3">
            <input type="text" name="lastName" value={model.lastName} onChange={handleChange} placeholder={labels.prenume} />
            <label>{labels.prenume}</label>
          </div>

          <div className="form-floating mb-3">
            <input type="email" name="email" value={model.email} onChange={handleChange} placeholder={labels.email} />
            <label>{labels.email}</label>
          </div>

          <div className="form-floating mb-3">
            <input type="password" name="password" value={model.password} onChange={handleChange} placeholder={labels.parola} />
            <label>{labels.parola}</label>
          </div>

          <div className="form-floating mb-3">
            <input type="password" name="passwordRepeat" value={model.passwordRepeat} onChange={handleChange} placeholder={labels.confirmareParola} />
            <label>{labels.confirmareParola}</label>
          </div>
          <ErrorBox>{error || data.error}</ErrorBox>

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_KEY || ""}
            onChange={(value) => setModel((model) => ({ ...model, captcha: value }))}
          />
          <Button type="submit" onClick={handleSubmit}> {translate("Înregistrează-te")} </Button>
          <Button to="/home" color="btn-outline-warning">
            <Fa duotone="circle-arrow-left fa-xl fa-fw" /> {translate("Pagina anterioară")}
          </Button>
        </>
      )}
    </LoginContainer>
  );
};

export default RegisterPage;
