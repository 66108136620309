import React from 'react'
import { Link } from 'react-router-dom'

const MottoImage = () => {
    return (
        <Link to="/" className="d-flex justify-content-center">
            <img src="/images/mentor-logo.png" className="img nav-logo" alt="" />
        </Link>
    )
}

export default MottoImage