import React from 'react'
import MottoImage from './MottoImage'
import { useTranslate } from '../common/lang'

const Motto = ({ children = "" as string | JSX.Element, showHeader = false }) => {
    const translate = useTranslate();
    const motto = translate('Mentoring pentru a implica, a conecta și <br />a face puternici tinerii, <span class=\'text-primary\'> VIITORUL NOSTRU</span>!');
    return (
        <div className='mb-4'>
            {showHeader && <MottoImage />}
            <p className='text-center'>
                <span dangerouslySetInnerHTML={{__html:motto}}></span>
            </p>
            {children && <h2 className='ps-5'>{children}</h2>}
        </div>
    )
}

export default Motto