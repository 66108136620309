import LoginContainer from "../../components/LoginContainer";
import { SyntheticEvent, useState } from "react";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";
import { passwordRecover } from "../../common/user";
import { useTranslate } from "../../common/lang";
import Button from "../../components/Button";
import Fa from "../../components/FontAwesome";

const PasswordRecoverPage = () => {
  const [email, setEmail] = useState("");
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<SqlError>();
  const translate = useTranslate();
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    passwordRecover(email)
      .then(() => {
        setCompleted(true);
      })
      .catch(setError);
  };
  return (
    <LoginContainer>
      <Title text="Recuperare parolă" />
      {!completed && (
        <>
          <div className="form-floating mb-3">
            <input type="email" name="email" required value={email} onChange={(e) => {
              setEmail(e.target.value);
              setError(undefined);
            }} placeholder={translate("Adresa de email")} />
            <label htmlFor="email">{translate("Adresa de email")}</label>
          </div>
          <p>
            <small>{translate("Vei primi un email cu link pentru resetare parolă")}</small>
          </p>
          <ErrorBox>{error}</ErrorBox>
          <Button type="submit" onClick={handleSubmit}>
            {translate("Trimite email")}
          </Button>
        </>
      )}
      {completed && <span>
        {translate("Un email a fost trimis la @email. Verifică dacă ai primit email-ul în Inbox sau în folderul Spam (email nedorit)").replace("@email", email)}
      </span>}
      <p>
        <small>
          <Button to="/login" color="btn-outline-warning">
            <Fa duotone="circle-arrow-left fa-xl fa-fw" /> {translate("Înapoi la conectare")}
          </Button>
        </small>
      </p>
    </LoginContainer>
  );
};

export default PasswordRecoverPage;
