import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { UserEntity } from '../../../../backend/src/shared/entity';
import FaRole from '../../components/Icons/FaRole';
import { UserRole } from '../../common/user';
import Loading from '../../components/Loading/Loading';

const ApproveCoordinator = () => {
    const users = useQuery({
        queryKey: ['getUsersByRoleQuery'],
        queryFn: () => sql.getUsersByRoleQuery({ role: "Coordonator" as UserRole }) as Promise<Array<UserEntity>>
    });
    const [saving, setSaving] = React.useState(false);
    const handleSave = (user: UserEntity) => {
        setSaving(true);
        sql
            .saveEntity("dbo.User", { Id: user.Id, Approved: !user.Approved })
            .then(() => users.refetch())
            .finally(() => setSaving(false));
    }
    if (users.isLoading || saving) return <Loading />;
    return (
        <ul className='nav flex-column'>
            {users.data?.map(user => (
                <li key={user.Id}>
                    <label className='py-2'>
                        <input type='checkbox' className='me-2' checked={user.Approved} onChange={() => handleSave(user)} />
                        <FaRole role={user.Role as UserRole} /> &nbsp; {user.Name}
                    </label>
                </li>
            ))}
        </ul>
    )
}

export default ApproveCoordinator