import React from 'react'
import Form from '../Form'
import { useUser } from '../../hooks/useUser';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import Title from '../Title';
import { FeedbackEntity, MentoringSessionEntity, UserEntity } from '../../../../backend/src/shared/entity';
import Loading from '../Loading/Loading';
import Lang from '../Lang';
import FeedbackParent from './FeedbackParent';
import { useTranslate } from '../../common/lang';
import { localeDateTime } from '../../common/utils';

const FeedbackForm = () => {
    const { idMentoringSession = 0, idParentFeedback = 0, idUserTo = 0, idForum = 0 } = useParams();
    const { user } = useUser();
    const [model, setModel] = React.useState<{ idMentoringSession: number, feedback: string | undefined, subject: string }>({ idMentoringSession: +idMentoringSession, feedback: undefined, subject: "" });
    const [isSavnig, setIsSaving] = React.useState(false);
    const navigate = useNavigate();
    const translate = useTranslate();
    const userTo = useQuery({
        queryKey: ['feedbackUserQuery', idUserTo, user.account?.Id],
        queryFn: () => sql.feedbackUserQuery({ idUserTo, idUser: user.account?.Id }) as Promise<UserEntity[]>,
        enabled: !!user.account?.Id,
        refetchOnWindowFocus: false,
    });
    const parent = useQuery({
        queryKey: ['parentFeedbackQuery', idUserTo, user.account?.Id],
        queryFn: () => sql.getEntity('dbo.Feedback', +idParentFeedback),
        enabled: !!+idParentFeedback,
        refetchOnWindowFocus: false,
    });

    const sessions = useQuery({
        queryKey: ['mentoringSessionsByIdMenteeQuery', user.account?.Id],
        queryFn: () => sql.mentoringSessionByIdUserQuery({ idUser: user.account?.Id }) as Promise<MentoringSessionEntity[]>,
        enabled: !!user.account?.Id && ['Mentorat', 'Mentor'].includes(user.account?.Role),
        refetchOnWindowFocus: false,
    });
    const isLoading = (...params: UseQueryResult<any, any>[]) => params.some(p => p.isInitialLoading);
    const saveFeedback = () => {
        setIsSaving(true);
        sql.saveEntity('dbo.Feedback',
            {
                IdUserTo: userTo.data?.[0]?.Id,
                IdForum: +idForum,
                Subject: model.subject,
                Message: model.feedback,
                IdUserFrom: user.account?.Id,
                MentoringSession: sessionDetail(model.idMentoringSession) || parent.data?.MentoringSession,
                IdParentFeedback: +(idParentFeedback || 0)
            } as FeedbackEntity)
            .then(() => navigate(-1))
            .catch(console.error)
            .finally(() => setIsSaving(false));
    }
    if (isLoading(userTo, parent, sessions) || isSavnig) return <Loading />;
    if (userTo.data?.[0] && undefined === model.feedback) setModel((model) => ({ ...model, feedback: `<p>Dear ${userTo.data[0].Name}, </p><p></p> ` }));
    const sessionDetail = (id: number) => {
        let s = sessions.data?.find(s => s.Id === id)
        return s ? `#${(s as any).SessionIndex} - ${localeDateTime(s.DateProposal)} ( ${user.role === 'Mentor' ? s.Mentee : s.Mentor} )` : '';
    }
    return (
        <Form onSubmit={saveFeedback}>
            <Title text="Trimite observație" />
            {parent.data && <FeedbackParent message={parent.data.Message} />}
            {!parent.data && sessions.data && sessions.data.length > 0 &&
                <Lang>
                    <select className='form-control rounded-5 mb-3' value={model.idMentoringSession} onChange={(e) => setModel((model) => ({ ...model, idMentoringSession: +e.target.value }))}>
                        <option value="">{translate("— Selecție sesiune (opțional) —")}</option>
                        {sessions.data.map((s) => (
                            <option key={s.Id} value={s.Id}>{sessionDetail(s.Id)}</option>
                        ))}
                    </select>
                </Lang>
            }
            {!idForum &&
                <Lang text="Subiect">
                    <input type="text" className="form-control rounded-5 mb-3" value={model.subject} onChange={(e) => setModel((model) => ({ ...model, subject: e.target.value }))} />
                </Lang>
            }
            <Editor
                //apiKey='fkiiliatixbusdrzfszkz9n52qoiatsjhofdgz52mxltjutk'
                tinymceScriptSrc="/tinymce/tinymce.min.js"
                value={model.feedback}
                init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                        'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: `
                            undo redo | formatselect | bold italic backcolor | 
                            alignleft aligncenter alignright alignjustify | 
                            bullist numlist outdent indent | removeformat`,
                }}
                onEditorChange={(content) => setModel((model) => ({ ...model, feedback: content }))}
            />
            <button type="submit" className="btn btn-primary my-3 w-100" disabled={isSavnig}>
                <Lang text="Trimite" />
            </button>
        </Form>
    )
}

export default FeedbackForm