import React from 'react';

interface StartCardProps {
  title: string;
  count: number;
  color: string;
  onViewAllClick: () => void;
}

const StartCard: React.FC<StartCardProps> = ({ title, count, color, onViewAllClick }) => {
  return (
    <div className={`stat-card ${color}`}>
      <h2>{title}</h2>
      <p>{count}</p>
      <button className="view-button" onClick={onViewAllClick}>View All</button>
    </div>
  );
};

export default StartCard;