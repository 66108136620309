import { ChangeEvent, SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { logoutUser, passwordReset } from "../../common/user";
import { useUser } from "../../hooks/useUser";
import LoginContainer from "../../components/LoginContainer";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";
import { useTranslate } from "../../common/lang";
import Button from "../../components/Button";

const PasswordResetPage = () => {
  const { token } = useParams();
  const translate = useTranslate();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [model, setModel] = useState({
    password: "",
    passwordRepeat: "",
  });

  const [error, setError] = useState<SqlError>();
  if (user.email) logoutUser().then(setUser);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({ ...model, [e.target.name]: e.target.value }));
    setError(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (model.password !== model.passwordRepeat)
      return setError({
        number: "",
        message:
          translate("<b>Crează o parolă</b> și <b>repetă parola</b> trebuie să fie identice"),
      });
    passwordReset(token as string, model.password)
      .then(() => {
        setUser();
        navigate("/");
      })
      .catch(setError);
  };

  return (
    <LoginContainer>
      <Title text="Resetare parolă" />
      <div className="form-floating mb-3">
        <input type="password"
          name="password"
          id="password"
          placeholder={translate("Parolă")}
          required
          value={model.password}
          onChange={handleChange} />
        <label htmlFor="password">{translate("Parolă")}</label>
      </div>

      <div className="form-floating mb-3">
        <input type="password"
          name="passwordRepeat"
          id="passwordRepeat"
          placeholder={translate("Confirmare Parolă")}
          required
          value={model.passwordRepeat}
          onChange={handleChange} />
        <label htmlFor="passwordRepeat">{translate("Confirmare Parolă")}</label>
      </div>
      <ErrorBox>{error}</ErrorBox>
      <Button type="submit" onClick={handleSubmit}>{translate("Setează noua parolă")}</Button>
    </LoginContainer>
  );
};

export default PasswordResetPage;
