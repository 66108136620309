import { Link } from 'react-router-dom';
import { useTranslate } from '../../common/lang';
import Fa from '../../components/FontAwesome';
import { useNotification } from '../../hooks/useNotification';

const PushNotification = () => {
    const { notifications, removeNotification } = useNotification();
    const translate = useTranslate();
    return (
        <div>
            <p className='text-primary lead'><Fa duotone='bell me-2' /> {translate("Notificări")}</p>
            <hr />
            {
                notifications
                    .map((notification, index) => (
                        <div key={index} className='row'>
                            <div className="d-flex justify-content-between">
                                <p style={{ width: "90%" }}>
                                    <b>{notification.title}</b>
                                    <br />
                                    {notification.body}
                                </p>
                                <Link to="" onClick={() => removeNotification(notification)}>
                                    <Fa duotone='trash text-danger mt-3' />
                                </Link>
                            </div>
                        </div>
                    ))
            }
        </div>
    )
}

export default PushNotification
