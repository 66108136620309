import React from 'react'
import Title from '../Title';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { MentoringSessionEntity, MentoringEntity, UserEntity } from '../../../../backend/src/shared/entity';
import { useUser } from '../../hooks/useUser';
import Loading from '../Loading/Loading';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Fa from '../FontAwesome';
import { localeDateTime } from '../../common/utils';

type MentorSessionEntity = MentoringEntity & MentoringSessionEntity & { Mentee: String, idMentoringSession: number, CompletedSessions: number };

const MentorSessions = () => {
    const { user } = useUser();
    const { date = '' } = useParams();
    const navigate = useNavigate();
    const relations = useQuery({
        queryKey: ['mentorRelationshipQuery', user.account?.Id],
        queryFn: () => sql.mentorRelationshipQuery({ idMentor: user.account?.Id }) as Promise<Array<MentorSessionEntity>>,
        enabled: !!user.account?.Id
    });
    const users = useQuery({
        queryKey: ['usersQuery'],
        queryFn: () => sql.getUsersPartialQuery() as Promise<Partial<UserEntity>[]>,
        enabled: true
    });
    if (relations.isLoading) return <Loading />
    if(date && relations.data?.length === 1 && window.location.pathname.includes('/select-mentee/')) {
        setTimeout(()=>navigate(`/mentoring/${(relations.data as any)[0].Id[0]}/${date}/-2`));
    }
    const menteeName = (r: MentorSessionEntity) => {
        const mentee = users.data?.find(u => u.Id === r.IdMentee);
        return mentee ? mentee.Name : r.Mentee;
    }
    return (
        <>
            <Title text='Sesiuni de mentorare' />
            <ul className='nav flex-column'>
                {
                    relations.data?.map(r => (
                        <li key={(r as any).Id[0]} className='nav-link'>
                            <Link to={`/mentoring/${(r as any).Id[0]}/${date}`}>
                                <SessionCalendarStatus {...r} /> {menteeName(r)}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </>
    )
}

export default MentorSessions

const SessionCalendarStatus = (r: MentorSessionEntity) => {
    if (r.CompletedSessions === r.Sessions) return <span className='text-success'>#{r.Sessions}/{r.Sessions}<Fa duotone='check-double mx-2' /></span>;
    if (r.DateProposal && !(r.MenteeAccepted && r.MentorAccepted)) return <span className='text-danger'>#{r.CompletedSessions + 1} {localeDateTime(r.DateProposal)} <Fa duotone='circle-question text-danger mx-2' /></span>;
    if (r.CompletedSessions === 0 || !r.DateProposal) return <span className='text-danger'>#{r.CompletedSessions + 1}<Fa duotone='triangle-exclamation fa-lg mx-2' /> </span>;
    return <span className='text-success'>#{r.CompletedSessions} {localeDateTime(r.DateProposal)} </span>;
}
