import React, { useEffect } from 'react'
import { useUser } from '../../hooks/useUser';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { useWebSocket } from '../../hooks/useWebSocket';
import Avatar from '../Avatar';
import Title from '../Title';
import Loading from '../Loading/Loading';
type FeedbackSummary = {
    Avatar: string;
    Unread: number;
    IdUserFrom: number;
    Username: string;
    Date: Date;
}
const Feedback = () => {
    const { user } = useUser();
    const feedbackSummaryQuery = useQuery({
        queryKey: ['feedbackSummaryQuery', user.account?.Id],
        queryFn: () => sql.feedbackSummaryQuery({ idUser: user.account?.Id }) as Promise<Array<FeedbackSummary>>,
        enabled: !!user.account?.Id,
    });
    const socket = useWebSocket();
    const feedbackUrl = (feedback: FeedbackSummary) => feedback.Date ? `/feedback/${feedback.IdUserFrom}` : `/feedback-form/0/${feedback.IdUserFrom}`;
    useEffect(() => {
        const onMessage = (event: MessageEvent) => {
            const [action] = event.data.split(':');
            console.log('onMessage', action);
            if (action === 'feedbackCountChanged') {
                console.log('feedbackCountChanged');
                feedbackSummaryQuery.refetch();
            }
        };
        socket.addEventListener('message', onMessage);
        return () => socket.removeEventListener('message', onMessage);
    }, [socket]);

    if(feedbackSummaryQuery.isLoading) return <Loading />;

    return (
        <ul className='nav flex-column'>
            <li>
                <Title text='Observație' />
                <hr />
            </li>
            {feedbackSummaryQuery.data?.map(feedback => (
                <li key={feedback.IdUserFrom} className='nav-item'>
                    <Link to={feedbackUrl(feedback)} className='nav-link position-relative'>
                        <Avatar src={feedback.Avatar} />  <span className={feedback.Unread ? 'fw-bold' : ''}>{feedback.Username}</span>
                        {feedback.Unread > 0 && <span className="badge bg-danger rounded-pill position-absolute top-0 start-100">{feedback.Unread}</span>}
                    </Link>
                </li>
            ))}
            {
                !feedbackSummaryQuery.data?.length &&
                <li className='nav-item mt-4'>
                    <Button to='/feedback-form/0/0' text='Trimite observație' />
                </li>
            }
        </ul>
    )
}

export default Feedback