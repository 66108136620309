import { createContext, useContext, useEffect, useState } from "react";
import { WS_URL } from "../common/env";
import { useUser } from "./useUser";
import { getUserProp } from "../common/user";

let url = WS_URL || window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/ws';
const socketUrl = url.replace('http', 'ws');
const uuid = getUserProp('uuid') || '';
console.log({ socketUrl, uuid });
const SocketContext = createContext<WebSocket>(null as any);
const webSocket = new WebSocket(`${socketUrl}/${uuid}`);
// webSocket.addEventListener('message', (e) => console.log('WebSocket received »', e.data));

export function WebSocketProvider({ children }: { children: React.ReactNode }) {
    const { user } = useUser();
    const [prevUuid, setPrevUuid] = useState<string>(user.uuid);
    const [socket, setSocket] = useState<WebSocket>(webSocket);
    // socket.onerror = (e) => socket.readyState === WebSocket.CLOSED && setSocket(new WebSocket(socketUrl));
    socket.onerror = (e) => console.error('WebSocket error', e);
    socket.onopen = () => console.info('WebSocket connected successfully');
    useEffect(() => {
        if (prevUuid !== user.uuid) {
            socket && socket.readyState === WebSocket.OPEN && socket.close(); // close old socket on unmount
            setSocket(new WebSocket(`${socketUrl}/${user.uuid}`)); // reset socket on user change
            setPrevUuid(user.uuid);
        }
        const onFocusReceived = () => {
            // console.log('onFocusReceived » WebSocket.readyState :', socket.readyState);
            setTimeout(() => (socket.readyState === WebSocket.CLOSED && setSocket(new WebSocket(`${socketUrl}/${user.uuid}`))), 500);
        }
        window.addEventListener('focus', onFocusReceived); //window.addEventListener('visibilitychange', onFocusReceived);
        return () => {
            window.removeEventListener('focus', onFocusReceived); //window.removeEventListener('visibilitychange', onFocusReceived);
        }
    }, [user, socket]);
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
}

export const useWebSocket = () => {
    return useContext(SocketContext);
}
