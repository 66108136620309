import React from 'react'
import Motto from '../Motto'
import Button from '../Button'
import { useTranslate } from '../../common/lang'

const About = () => {
    const translate = useTranslate();
    return (
        <div className='row'>
            <Motto>{translate("Despre")}</Motto>
            <div className="col-12 px-4">
                {/* <Button text='Ghid utilizare aplicație' /> */}
                {/* <Button to={`/files/What_is_Mentoring_${lang}.pdf`} target='_blank'>{translate("Ce înseamnă mentorarea?")}</Button> */}
                <Button to={`/about/mentoring-is`}>{translate("Ce înseamnă mentorarea?")}</Button>
                <Button to='/about/mentor-plus'>Mentor+ Project</Button>
                <Button to='/about/child-protection-policy'>{translate("Politica de Protecție a Copilului")}</Button>
                <Button to='/about/partnership'>{translate("Parteneriat")}</Button>
                <Button to='/about/eu-funding' color='btn-success'>{translate("Finanțare UE")}</Button>
                <Button to='/about/contact-us' color='btn-primary'>{translate("Contactează-ne")}</Button>
            </div>
        </div>
    )
}

export default About