import React from 'react'
import { Labels, useTranslate } from '../common/lang';

const Lang = ({ text = "" as Labels, children = undefined as any, hideWhen = false, required = false }) => {
    const translate = useTranslate();
    if (hideWhen) return null;
    if (children) {
        return (
            <label className={required ? 'required' : ''}>
                <span dangerouslySetInnerHTML={{ __html: translate(text) }}></span>
                {children}
            </label>
        )
    }
    return (
        <>
            {translate(text)}
        </>
    )
}

export default Lang;