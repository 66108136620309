import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MentoringEntity } from '../../../../backend/src/shared/entity';

interface MentoringTableProps {
  mentoring: MentoringEntity[];
  userMap: { [key: string]: string };
}

const MentoringTable: React.FC<MentoringTableProps> = ({ mentoring, userMap }) => {
  const navigate = useNavigate();

  return (
    <table>
      <thead>
        <tr>
          <th>Coordinator</th>
          <th>Mentor</th>
          <th>Mentee</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Sessions</th>
        </tr>
      </thead>
      <tbody>
        {mentoring.map((relation) => (
          <tr
            key={relation.Id}
            onClick={() => navigate(`/coordinator/mentoring-relationship/${relation.Id}/0`)}
            style={{ cursor: 'pointer' }}
          >
            <td>{userMap[relation.IdCoordinator] || "Unknown"}</td>
            <td>{userMap[relation.IdMentor] || "Unknown"}</td>
            <td>{userMap[relation.IdMentee] || "Unknown"}</td>
            <td>{relation.DataStart ? new Date(relation.DataStart).toLocaleString() : "N/A"}</td>
            <td>{relation.DataStop ? new Date(relation.DataStop).toLocaleString() : "N/A"}</td>
            <td>{relation.Sessions}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MentoringTable;