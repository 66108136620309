import React from 'react'
import { MentoringSessionEntity } from '../../../../backend/src/shared/entity'
import { filterBySelectedDate, formatDate, roomateFor } from '../../common/utils'
import { Link } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { useTranslate } from '../../common/lang'

const CalendarMentor = ({ selectedDate = undefined as Date | undefined, sessions = [] as MentoringSessionEntity[] }) => {
    const { user } = useUser();
    const translate = useTranslate();
    if (!selectedDate) return null;
    return (
        <div className="card border-0">
            <div className="card-body">
                <h6 className="card-title text-center p-2">{formatDate(selectedDate as Date, 'short')}</h6>
                {sessions.filter(session => filterBySelectedDate(selectedDate, session)).map(session => (
                    <p key={session.Id} className="card-text">
                        <Link to={`/mentoring/${session.IdMentoring}`}>
                            {roomateFor(user.role, session)}
                        </Link>
                    </p>
                ))}
                <a href={`/mentoring/select-mentee/${(selectedDate as Date).toJSON().substring(0, 10)}`} className="btn btn-primary">{translate('Popune selecția ca dată de întâlnire')}</a>
            </div>
        </div>
    )
}

export default CalendarMentor