import React, { useState } from 'react'
import sql from '../../common/sql';
import { ForumEntity } from '../../../../backend/src/shared/entity';
import Lang from '../Lang';
import Form from '../Form';
import Title from '../Title';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';

const ForumForm = () => {
  const { user } = useUser();
  const [model, setModel] = useState<ForumEntity>({ Id: 0, Topic: "", Description: "", IdUser: user.account?.Id || 0, UserName: user.account?.Name || "", Date: new Date() });
  const [isSavnig, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsSaving(true);
    sql.saveEntity('dbo.Forum', model)
      .then(forum => navigate(`/forum-posts/${forum.Id}`))
      .catch(console.error)
      .finally(() => setIsSaving(false));
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Title text="Topic forum" />
      <Lang text="Topic forum">
        <input type="text" className="form-control rounded-5 mb-3" value={model.Topic} onChange={(e) => setModel((model) => ({ ...model, Topic: e.target.value }))} />
      </Lang>
      <Editor
        // apiKey='fkiiliatixbusdrzfszkz9n52qoiatsjhofdgz52mxltjutk'
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        value={model.Description}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
            'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: `
                    undo redo | formatselect | bold italic backcolor | 
                    alignleft aligncenter alignright alignjustify | 
                    bullist numlist outdent indent | removeformat`,
        }}
        onEditorChange={(content) => setModel((model) => ({ ...model, Description: content }))}
      />
      <button type="submit" className="btn btn-primary my-3 w-100" disabled={isSavnig}>
        <Lang text="Trimite" />
      </button>
    </Form>
  )
}

export default ForumForm