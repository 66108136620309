import React, { useEffect } from 'react'
import { useUser } from '../../hooks/useUser';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FeedbackEntity } from '../../../../backend/src/shared/entity';
import { localeDateTime } from '../../common/utils';
import Loading from '../Loading/Loading';
import Lang from '../Lang';
import Fa from '../FontAwesome';
import Button from '../Button';
import Title from '../Title';
import FeedbackParent from './FeedbackParent';

const FeedbackDetail = ({ idForum = 0 }) => {
    const { user } = useUser();
    const { idUserFrom = 0 } = useParams();
    const navigate = useNavigate();
    const queryResult = useQuery({
        queryKey: ['feedbackDetailQuery', user.account?.Id],
        queryFn: () => (idForum
            ? sql.forumPostsQuery({ idForum })
            : sql.feedbackDetailQuery({ idUserFrom, idUser: user.account?.Id })) as Promise<Array<FeedbackEntity>>,
        enabled: !!user.account?.Id,
    });

    useEffect(() => window.scrollTo(0, document.body.scrollHeight), [queryResult.data]);

    if (queryResult.isLoading) return <Loading />
    if (queryResult.data) {
        queryResult.data.forEach(feedback => {
            if (feedback.IdUserTo === user.account?.Id && feedback.Unread) {
                sql.saveEntity('dbo.Feedback', { Id: feedback.Id, Unread: 0 });
            }
        });
    }
    const getParentFeedback = (feedback: FeedbackEntity) => {
        return queryResult.data?.find(f => f.Id === feedback.IdParentFeedback)?.Message || '';
    }

    return (
        <>
            {!idForum && <Title text='Pagina anterioară' />}
            {queryResult.data?.map(feedback => (
                <div className={`card alert my-3 ${feedback.IdUserFrom === user.account?.Id ? 'align-self-end ms-4 alert-light' : 'me-4 alert-warning'}`} key={feedback.Id}>
                    <div className="card-body">
                        {
                            feedback.Subject && <span className='text-muted fs-5 fst-italic'>{feedback.Subject}</span>
                        }
                        {
                            !!feedback.MentoringSession && <FeedbackParent message={`<p>${feedback.MentoringSession} </p>`} title='Legat de sesiunea' />
                        }
                        {
                            !!feedback.IdParentFeedback && <FeedbackParent message={getParentFeedback(feedback)} />
                        }
                        <div dangerouslySetInnerHTML={{ __html: feedback.Message }} />
                        {(feedback.IdUserTo === user.account?.Id || (idForum && feedback.IdUserFrom !== user.account?.Id)) &&
                            <Link to={`/feedback-form/${feedback.Id}/${feedback.IdUserFrom}/0/${idForum}`} className="card-link">
                                <Fa duotone='comment-dots' /> <Lang text='Răspunde' />
                            </Link>
                        }
                        <hr />
                        <i className="card-subtitle mb-2 text-body-secondary fs-small">{feedback.UserNameFrom}, {localeDateTime(feedback.Date)}</i>
                    </div>
                </div>
            ))}

            {
                idForum ? <>
                    <Button to={`/feedback-form/0/0/0/${idForum}`}>
                        <Fa duotone='comment-dots' /> <Lang text='Răspunsul meu' />
                    </Button>
                </>
                    : user.role === 'Coordonator' ?
                        <Button to="" onClick={() => navigate(-1)}><Fa duotone="circle-arrow-left fa-xl fa-fw" /> <Lang text='Pagina anterioară' /></Button> :
                        <Button to={`/feedback-form/0/${idUserFrom}`} text='Trimite observație' />

            }
        </>
    )
}

export default FeedbackDetail

