import React from 'react'
import MentoringRelationship from '../../pages/coordinator/MentoringRelationship';

const CoordinatorSessions = () => {

    return (
        <MentoringRelationship />
    )
}

export default CoordinatorSessions