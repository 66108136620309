import React, { HTMLAttributeAnchorTarget, HTMLInputTypeAttribute } from 'react'
import { Link } from 'react-router-dom'
import { Labels, useTranslate } from '../common/lang'

const Button = ({ to = "", target="" as HTMLAttributeAnchorTarget, color = "btn-warning", type = "" as HTMLInputTypeAttribute, width = "w-100", disabled = false, text = "" as Labels, children = <></> as any, onClick = (e: React.SyntheticEvent) => { } }) => {
    const translate = useTranslate();
    return (
        <Link aria-disabled target={target} to={to} type={type} className={`btn ${color} ${disabled && 'disabled'} ${width} my-2 text-center rounded-5`} onClick={onClick}>
            {translate(text) || children}
        </Link>
    )
}

export default Button
