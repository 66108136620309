import React from 'react'
import Form from '../../components/Form'
import { useUser } from '../../hooks/useUser';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { useNavigate, useParams } from 'react-router-dom';
import { MentoringEntity, UserEntity } from '../../../../backend/src/shared/entity';
import Button from '../../components/Button';
import Lang from '../../components/Lang';
import CalendarPicker from '../../components/Session/CalendarPicker';
import Loading from '../../components/Loading/Loading';
import { useTranslate } from '../../common/lang';
import Title from '../../components/Title';
import FileUpload from '../../components/FileUpload/FileUpload';

const defaultModel = {
    IdMentee: 0,
    IdMentor: 0,
    Sessions: 0,
    DataStart: new Date(),
    DataStop: new Date()
} as MentoringEntity;

const MentoringRelationshipForm = () => {
    const { idMentoring, idMentee = 0 } = useParams();
    const [model, setModel] = React.useState({ ...defaultModel, IdMentee: +idMentee });
    const translate = useTranslate();
    const [saving, setSaving] = React.useState(false);
    const navigate = useNavigate();
    const { user } = useUser();
    const relationship = useQuery({
        queryKey: ['getMentoringRelationshipQuery', idMentoring],
        queryFn: () => idMentoring
            ? sql.getEntity("dbo.Mentoring", +idMentoring)
            : Promise.resolve(defaultModel),
        enabled: !!user.account?.Id
    });
    const mentees = useQuery({
        queryKey: ['availableMenteesQuery', user.account?.Id, +idMentoring! && model.IdMentee],
        queryFn: () => sql.availableMenteesQuery({ idCoordinator: user.account?.Id, idMentee: model.IdMentee }) as Promise<Array<UserEntity>>,
        enabled: !!user.account?.Id
    });
    const mentors = useQuery({
        queryKey: ['availableMentorsQuery', user.account?.Id],
        queryFn: () => sql.availableMentorsQuery({ idCoordinator: user.account?.Id }) as Promise<Array<UserEntity>>,
        enabled: !!user.account?.Id
    });

    const nextDayOf = (date: Date) => {
        let tomorrow = new Date(date || new Date());
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setModel({ ...model, [name]: +value || value });
    }

    const handleSave = () => {
        if (!model.IdMentee || !model.IdMentor || !model.Sessions || !model.DataStart || !model.DataStop) return window.alert(("Completați toate câmpurile."));
        setSaving(true);
        sql.saveEntity("dbo.Mentoring", { ...model, IdCoordinator: user.account?.Id })
            .then(() => navigate(-1))
            .finally(() => setSaving(false));
    }
    const handleDelete = () => {
        if (!model.Id) return navigate(-1);
        if (!window.confirm(translate("Doriți să ștergeți relația."))) return;
        setSaving(true);
        sql.deleteEntity("dbo.Mentoring", model.Id)
            .then(() => navigate(-1))
            .finally(() => setSaving(false));
    }
    if (relationship.isLoading || mentees.isLoading || mentors.isLoading || saving) return <Loading />

    if (relationship.data?.Id && !model.Id) setModel({ ...relationship.data, DataStart: new Date(relationship.data.DataStart), DataStop: new Date(relationship.data.DataStop) });

    return (
        <Form onSubmit={handleSave}>
            <Title text={+idMentoring! ? 'Editare relație de mentorat' : 'Adăugare relație de mentorat'} />
            <div className='form-group mb-3'>
                <Lang text='Mentorat' />
                <select className='form-control' name='IdMentee' disabled={!!+idMentoring!} value={model.IdMentee} onChange={handleChange} required>
                    <option value="">{translate('— Selectează —')}</option>
                    {mentees.data?.map(u =>
                        <option key={u.Id} value={u.Id}>{u.Name}</option>
                    )}
                </select>
            </div>
            <div className='form-group mb-3'>
                <Lang text='Mentor' />
                <select className='form-select' name="IdMentor" value={model.IdMentor} onChange={handleChange} required>
                    <option value="">{translate('— Selectează —')}</option>
                    {mentors.data?.map(u =>
                        <option key={u.Id} value={u.Id}>{u.Name}</option>
                    )}
                </select>
            </div>
            <div className='form-group mb-3'>
                <Lang text="Număr de ședințe" />
                <input type='number' name="Sessions" className='form-control' value={model.Sessions} onChange={handleChange} required min={3} />
            </div>
            <div className='form-group mb-3'>
                <Lang text="Data începerii" />
                <CalendarPicker setDate={e => setModel({ ...model, DataStart: e as Date })} date={model.DataStart} dateOnly />
            </div>
            <div className='form-group mb-3'>
                <Lang text="Data finalizării" />
                <CalendarPicker setDate={e => setModel({ ...model, DataStop: e as Date })} date={model.DataStop} minDate={nextDayOf(model.DataStart)} dateOnly />
            </div>
            <Lang text="Acord de mentorat" />
            <FileUpload files={JSON.parse(model.Agreement||'[]')} multiple={false} accept='*' onChange={e => setModel({ ...model, Agreement: JSON.stringify(e) })} />

            <button type='submit' className='btn btn-primary w-100'>{translate('Salvează')}</button>
            {!!(+idMentoring!) && <Button text='Șterge relația de mentorare' color='btn-outline-danger' onClick={e => [e.preventDefault(), handleDelete()]} />}
        </Form>
    )
}

export default MentoringRelationshipForm