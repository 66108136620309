import React from "react";
import { localeDateTime } from "../common/utils";

const sameKeyValue = (obj1: { [key: string]: any }, obj2: { [key: string]: any }) => {
    for (const key in obj1) {
        if(key.startsWith('__')) continue;
        // pentru DateTime ar trebui folosit : localeDateTime(obj1[key]) === localeDateTime(obj2[key])
        if (obj1[key] instanceof Date && obj2[key] instanceof Date) {
            if (localeDateTime(obj1[key]) !== localeDateTime(obj2[key])) {
                console.log('different key:', key, obj1[key], obj2[key]);
                return false;
            }
        }
        else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
            console.log('different key:', key, obj1[key], obj2[key]);
            return false;
        }
    }
    return true;
}

const useCache = (data: { [key: string]: any }) => {
    const [cached] = React.useState({ ...data });
    return () => {
        return !sameKeyValue(data, cached);
    }
};

export default useCache;