import React from 'react'
import { ChatEntity } from '../../../../backend/src/shared/entity'
import { useUser } from '../../hooks/useUser';
import Avatar from '../Avatar';

const ChatMessage = ({ From, To, Stamp, Message, avatar }: ChatEntity & { avatar: string | undefined }) => {
    const { user: { uuid } } = useUser();
    const className = `w-100 d-flex m-2 ${From === uuid && 'justify-content-end'}`;
    return (
        <div className={className}>
            <div className={`w-75 d-flex align-items-start ${From === uuid ? 'justify-content-end me-2' : 'justify-content-start'}`}>
                {From === uuid && <span className='chat-message-from'>{Message}</span>}
                <Avatar src={avatar} />
                {To === uuid && <span className='chat-message-to'>{Message}</span>}
            </div>
        </div>
    )
}

export default ChatMessage

