import React from 'react';
import { PartnerEntity } from '../../../../backend/src/shared/entity';

interface PartnerTableProps {
  partners: PartnerEntity[];
}

const PartnerTable: React.FC<PartnerTableProps> = ({ partners }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Partner Name</th>
          <th>Country</th>
          <th>Website</th>
        </tr>
      </thead>
      <tbody>
        {partners.map((partner) => (
          <tr key={partner.Id}>
            <td>{partner.Name}</td>
            <td>{partner.Country}</td>
            <td>{partner.Website || "N/A"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PartnerTable;