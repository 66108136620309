import React from 'react'
import Motto from '../Motto'
import Button from '../Button'
import { useUser } from '../../hooks/useUser'
import { useQuery } from '@tanstack/react-query'
import sql from '../../common/sql'
import { MentoringEntity, MentoringSessionEntity } from '../../../../backend/src/shared/entity'
import Loading from '../Loading/Loading'
import ErrorBox from '../ErrorBox'
import { useTranslate } from '../../common/lang'
import MentoringSessionProgress from './MentoringSessionProgress'
import Form from '../Form'
import CalendarPicker from './CalendarPicker'
import { localeDateTime } from '../../common/utils'
import Fa from '../FontAwesome'
import { useParams } from 'react-router-dom'
import MentoringSessionNext from './MentoringSessionNext'
import Title from '../Title'

const MenteeSessions = () => {
    const { date } = useParams();
    const { user } = useUser();
    const translate = useTranslate();
    const [isSavnig, setIsSaving] = React.useState(false);
    const { isLoading, data, error, refetch } = useQuery({
        queryKey: ['mentoringSessionsByIdMenteeQuery', user.account?.Id],
        queryFn: () => sql.mentoringSessionsByIdMenteeQuery({ idMentee: user.account?.Id }) as Promise<[Array<MentoringEntity>, Array<MentoringSessionEntity>, Array<MentoringSessionEntity>, Array<MentoringSessionEntity>]>,
        enabled: !!user.account?.Id
    });

    const feedback = useQuery({
        queryKey: ['unreadFeedbackQuery', user.uuid],
        queryFn: () => sql.unreadFeedbackQuery({ uuid: user.uuid }) as Promise<Array<{ Unread: number, Count: number }>>,
        enabled: !!user.account?.Id,
    });
    const [changeDate, setChangeDate] = React.useState<Date | undefined>(date ? new Date(`${date} 10:00:00`) : undefined);

    if (isLoading || feedback.isLoading || isSavnig) return <Loading />;
    if (error) return <ErrorBox>{error}</ErrorBox>;
    if (!data) return <div className="alert alert-warning" role="alert">{translate("Ceva nu a mers bine")}</div>

    const lastSession = data[1][0];
    const nextSession = data[2][0];
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1); // minDate is tomorow
    const maxDate = new Date(data[0][0]?.DataStop || new Date());
    const mentoring = data[0][0];
    const handleSave = () => {
        if (!changeDate || localeDateTime(nextSession.DateProposal) === localeDateTime(changeDate)) return setChangeDate(undefined);
        setIsSaving(true);
        sql.saveEntity('dbo.MentoringSession', { ...nextSession, MenteeAccepted: new Date(), MentorAccepted: null, DateProposal: changeDate } as MentoringSessionEntity)
            .then(() => setChangeDate(undefined))
            .then(() => refetch())
            .finally(() => setIsSaving(false));
    }
    const handleAcceptProposal = () => {
        setIsSaving(true);
        sql.saveEntity('dbo.MentoringSession', { ...nextSession, MenteeAccepted: new Date() } as MentoringSessionEntity)
            .then(() => refetch())
            .finally(() => setIsSaving(false));
    }
    return (
        <div className='row'>
            <div className="col-12 px-4">
                <MentoringSessionProgress mentoring={data[0][0]} sessions={data[3]} />
                <div className="alert alert-warning" role="alert">{translate("Ultima ta întâlnire a fost pe")} : {lastSession ? localeDateTime(lastSession.DateProposal) : "——"}</div>
                <div className="alert alert-warning" role="alert">

                    <MentoringSessionNext nextSession={nextSession} />

                    {!!nextSession && !nextSession.MenteeAccepted && <Button color='btn-link mb-0 pb-0' onClick={e => [e.preventDefault(), handleAcceptProposal()]}>{translate("Acceptă data propusă")}</Button>}
                    {!!nextSession && !changeDate && <Button color='btn-link' onClick={e => [e.preventDefault(), setChangeDate(nextSession.DateProposal)]}>{translate("Solicit modificare")}</Button>}
                    {!!changeDate &&
                        <Form onSubmit={handleSave}>
                            <hr />
                            <p className='text-center fs-5'>{translate('Solicit modificare')}</p>
                            <CalendarPicker minDate={minDate} maxDate={maxDate} required date={changeDate} setDate={d => setChangeDate(d)} />
                            <button type='submit' className='btn btn-primary w-100 mt-3'>{translate('Salvează')}</button>
                            <Button color='btn-outline-danger' onClick={e => [e.preventDefault(), setChangeDate(undefined)]}>{translate("Anulează")}</Button>
                        </Form>
                    }
                </div>
                {!!mentoring?.Agreement && <Button to={'/' + JSON.parse(mentoring.Agreement)[0].filepath} target='_blank' color='btn-success'>Your Mentoring Agreement</Button>}
                <Button to='/feedback-form/0' text='Trimite observație' />
                {!!feedback.data?.[0]?.Count &&
                    <Button to="/feedback" color='btn-outline-primary'>
                        <span className="position-relative pe-3">
                            <Fa duotone='paper-plane me-2' />
                            {translate("Vizualizare observație")}
                            {!!feedback.data?.[0]?.Unread && <span className="badge bg-danger rounded-pill position-absolute top-0 start-100 translate-middle">{feedback.data?.[0]?.Unread}</span>}
                        </span>
                    </Button>
                }
            </div>
        </div>
    )
}

export default MenteeSessions
