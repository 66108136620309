import { Labels } from "../common/lang";
import Back from "./Back";
import Lang from "./Lang";

const Title = ({ text = "" as Labels, goBack = -1 as string | -1 }) => {
  return (<h4>
    <Back link={goBack} /> <Lang text={text} />
  </h4>);
};

export default Title;
