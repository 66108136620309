import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import sql from "../../common/sql";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslate } from "../../common/lang";
import { useUser } from "../../hooks/useUser"; // Hook to get user information

// Register the necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Define the types for data
interface MoodSubmission {
  Id: number;
  NumeUser: string;
  DataCompletare: string; // Date of submission
  MoodValue1: number;
  MoodValue2: number;
  MoodValue3: number;
  MoodValue4: number;
  MoodValue5: number;
  MoodValue6: number;
}

interface MentoringRelationship {
  IdMentor: number;
  IdMentee: number;
}

interface User {
  Id: number;
  Name: string;
  Role: string;
}

const MoodringGraph = () => {
  const translate = useTranslate();
  const { user } = useUser(); // Assume useUser returns the current user's information, including name and role

  // State to manage the selected user filter
  const [selectedUser, setSelectedUser] = useState<string>(() => {
    // If the user is a mentor, default to their username; otherwise, default to "All Users"
    return user.role === 'Mentor' ? user.name : 'All Users';
  });

  // State to manage the visibility of each mood value dataset
  const [visibility, setVisibility] = useState({
    MoodValue1: true,
    MoodValue2: true,
    MoodValue3: true,
    MoodValue4: true,
    MoodValue5: true,
    MoodValue6: true,
  });

  // Fetch mood submissions using react-query
  const {
    data: moodData,
    isLoading: isLoadingMoods,
    error: errorMoods,
  } = useQuery({
    queryKey: ["moodSubmissions"],
    queryFn: () =>
      sql.getEntity("dbo.MoodSubmissions", -1) as any as Promise<
        MoodSubmission[]
      >,
    enabled: true,
  });

  // Fetch mentor-mentee relationships using react-query
  const {
    data: mentoringData,
    isLoading: isLoadingMentoring,
    error: errorMentoring,
  } = useQuery({
    queryKey: ["mentoringData"],
    queryFn: () =>
      sql.getEntity("dbo.Mentoring", -1) as any as Promise<
        MentoringRelationship[]
      >,
    enabled: true, // Always fetch for mentor role
  });

  // Fetch users data to map mentor and mentee names
  const {
    data: usersData,
    isLoading: isLoadingUsers,
    error: errorUsers,
  } = useQuery({
    queryKey: ["usersData"],
    queryFn: () => sql.getEntity("dbo.User", -1) as any as Promise<User[]>,
    enabled: true, // Always fetch for mentor role
  });

  if (isLoadingMoods || isLoadingMentoring || isLoadingUsers)
    return <p>Loading...</p>;

  if (errorMoods || errorMentoring || errorUsers) {
    const errorMessage =
      (errorMoods instanceof Error ? errorMoods.message : "") ||
      (errorMentoring instanceof Error ? errorMentoring.message : "") ||
      (errorUsers instanceof Error ? errorUsers.message : "") ||
      "An unknown error occurred";

    return <p>Error: {errorMessage}</p>;
  }

  // Find the current user entity from usersData using user.name
  const currentUser = usersData?.find((u) => u.Name === user.name);

  if (!currentUser) {
    return <p>Current user not found.</p>;
  }

  const userId = currentUser.Id;
  const isMentorat = currentUser.Role === "Mentorat";
  const isMentor = currentUser.Role === "Mentor";
  const currentUserName = currentUser.Name;

  // Determine the mentees of the current mentor
  const menteeIds = isMentor
    ? mentoringData
        ?.filter((relation) => relation.IdMentor === userId)
        .map((relation) => relation.IdMentee)
    : [];

  const menteeNames = menteeIds
    ?.map((menteeId) => usersData?.find((u) => u.Id === menteeId)?.Name)
    .filter((name) => name);

  // Extract unique users for the dropdown
  const uniqueUsers = isMentorat
    ? [] // No dropdown for "Mentorat", as they should only see their own data
    : isMentor
    ? [currentUserName, ...(menteeNames || [])] // Mentor can see only their own and their mentees' data
    : ["All Users", ...(usersData?.map((user) => user.Name) ?? [])]; // Admin or other roles can see all users

  // Filter data based on the selected user
  const filteredData = isMentorat
    ? moodData?.filter((submission) => submission.NumeUser === currentUserName)
    : selectedUser === "All Users"
    ? moodData
    : moodData?.filter((submission) => submission.NumeUser === selectedUser);

  // Prepare data for the graph
  const labels = filteredData
    ? filteredData.map((submission) =>
        new Date(submission.DataCompletare).toLocaleDateString()
      )
    : [];

  // Prepare data for each mood value
  const moodValues = {
    MoodValue1: filteredData
      ? filteredData.map((submission) => submission.MoodValue1)
      : [],
    MoodValue2: filteredData
      ? filteredData.map((submission) => submission.MoodValue2)
      : [],
    MoodValue3: filteredData
      ? filteredData.map((submission) => submission.MoodValue3)
      : [],
    MoodValue4: filteredData
      ? filteredData.map((submission) => submission.MoodValue4)
      : [],
    MoodValue5: filteredData
      ? filteredData.map((submission) => submission.MoodValue5)
      : [],
    MoodValue6: filteredData
      ? filteredData.map((submission) => submission.MoodValue6)
      : [],
  };

  // Define dataset labels
  const datasetLabels = {
    MoodValue1: translate("Tratează-te bine"),
    MoodValue2: translate("Simte-te conectat"),
    MoodValue3: translate("Rămâi prezent"),
    MoodValue4: translate("Simte-te bine sau rău"),
    MoodValue5: translate("Implică-te"),
    MoodValue6: translate("Gestionează comportamentul"),
  };

  // Prepare datasets for the chart based on visibility state
  const datasets = [
    {
      label: datasetLabels.MoodValue1,
      data: moodValues.MoodValue1,
      borderColor: "rgba(75,192,192,1)",
      backgroundColor: "rgba(75,192,192,0.2)",
      fill: true,
      hidden: !visibility.MoodValue1,
    },
    {
      label: datasetLabels.MoodValue2,
      data: moodValues.MoodValue2,
      borderColor: "rgba(255,99,132,1)",
      backgroundColor: "rgba(255,99,132,0.2)",
      fill: true,
      hidden: !visibility.MoodValue2,
    },
    {
      label: datasetLabels.MoodValue3,
      data: moodValues.MoodValue3,
      borderColor: "rgba(54,162,235,1)",
      backgroundColor: "rgba(54,162,235,0.2)",
      fill: true,
      hidden: !visibility.MoodValue3,
    },
    {
      label: datasetLabels.MoodValue4,
      data: moodValues.MoodValue4,
      borderColor: "rgba(255,206,86,1)",
      backgroundColor: "rgba(255,206,86,0.2)",
      fill: true,
      hidden: !visibility.MoodValue4,
    },
    {
      label: datasetLabels.MoodValue5,
      data: moodValues.MoodValue5,
      borderColor: "rgba(75,192,192,1)",
      backgroundColor: "rgba(75,192,192,0.2)",
      fill: true,
      hidden: !visibility.MoodValue5,
    },
    {
      label: datasetLabels.MoodValue6,
      data: moodValues.MoodValue6,
      borderColor: "rgba(153,102,255,1)",
      backgroundColor: "rgba(153,102,255,0.2)",
      fill: true,
      hidden: !visibility.MoodValue6,
    },
  ];

  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Mood Submissions Over Time",
      },
    },
  };

  // Handle user selection from dropdown
  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUser(event.target.value);
  };

  // Handle checkbox change for dataset visibility
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [name]: checked,
    }));
  };

  // Handle print button click
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="moodring-graph">
      <h2>Moodring Graph</h2>

      {/* Conditionally render the user filter dropdown only if the user is not a "Mentorat" */}
      {!isMentorat && (
        <div className="user-filter">
          <label htmlFor="userSelect">Select User: </label>
          <select
            id="userSelect"
            value={selectedUser}
            onChange={handleUserChange}
          >
            {uniqueUsers.map((user) => (
              <option key={user} value={user}>
                {user}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Print Button */}
      <button onClick={handlePrint} className="btn btn-primary print-button">
        Print Graph
      </button>

      {/* Line Chart */}
      <Line data={data} options={options} />

      {/* Checkboxes for controlling visibility of each dataset */}
      <div
        className="moodring-checkboxes"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
        }}
      >
        {Object.keys(visibility).map((moodKey, index) => (
          <div key={moodKey} className="checkbox-container">
            <label className="checkbox-label">
              <input
                type="checkbox"
                name={moodKey}
                checked={visibility[moodKey as keyof typeof visibility]}
                onChange={handleCheckboxChange}
                className="checkbox-input"
              />
              {datasetLabels[moodKey as keyof typeof datasetLabels]}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoodringGraph;