import React, { createContext } from 'react'

export const FormContext = createContext<boolean>(true);

const Form = ({ children = null as any, onSubmit = () => { } }) => {
    const [valid, setValid] = React.useState(true);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    }
    const handleInvalid = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const elem = form.querySelector(`:not(form):not(fieldset):invalid`) as HTMLElement;
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: "center" });
            elem.focus();
        } else {
            console.warn("Nu am gasit elementul invalid al form-ului !");
        }
        Array.from(document.querySelectorAll(":invalid, :valid")).forEach((el: Element) => {
            el.setAttribute("aria-invalid", "true");
        });
        if (valid) {
            setValid(false);
        }
    };


    return (
        <FormContext.Provider value={valid}>
            <form onSubmit={handleSubmit} onInvalid={handleInvalid}>
                {children}
            </form>
        </FormContext.Provider>
    )
}

export default Form
