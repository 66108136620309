export interface MoodState {
    label: string;
    options: string[];
  }
  
  export const moodStates: MoodState[] = [
    {
      label: 'Tratează-te bine',
      options: [
        'Sunt foarte dur cu mine, mai ales când lucrurile merg prost.',
        'Uneori mă bat pe mine însumi pentru dificultăți.',
        'Sunt un amestec - uneori sunt autocritic, alteori sunt bine cu mine.',
        'În general, sunt suportiv și înțelegător cu mine când trec prin momente grele.',
        'Indiferent de ce se întâmplă, întotdeauna mă tratez cu bunătate și respect.'
      ]
    },
    {
      label: 'Simte-te conectat',
      options: [
        'Mă simt complet singur, ca și cum nu aș avea pe nimeni la care să mă întorc.',
        'Adesea simt că nu am mulți oameni în colțul meu.',
        'Uneori mă simt susținut, alteori mă simt singur.',
        'De obicei simt că am sprijin din partea prietenilor sau familiei.',
        'Întotdeauna știu că am oameni care mă susțin și care țin la mine.'
      ]
    },
    {
      label: 'Rămâi prezent',
      options: [
        'Sunt întotdeauna copleșit de gândurile și sentimentele mele.',
        'Îmi este greu să-mi păstrez mintea la ceea ce se întâmplă acum.',
        'Reușesc să rămân prezent uneori, dar deseori pierd urma.',
        'De obicei, pot să mă concentrez pe prezent fără să fiu distras de gândurile mele.',
        'Sunt foarte bun la a mă concentra și a nu lăsa gândurile să preia controlul.'
      ]
    },
    {
      label: 'Simte-te bine sau rău',
      options: [
        'Majoritatea zilelor mă simt rău și nu am energia necesară.',
        'Adesea mă lupt să mă simt bine și motivat.',
        'Zilele mele sunt un amestec - uneori bine, uneori rău.',
        'În general mă simt pozitiv și energic.',
        'Mă simt grozav și gata în fiecare zi.'
      ]
    },
    {
      label: 'Implică-te',
      options: [
        'Nu simt că fac prea multe; mă țin doar pentru mine.',
        'Adesea mă abțin de la a mă implica în lucruri.',
        'Mă implic uneori, dar nu constant.',
        'Sunt destul de angajat în ceea ce fac zilnic.',
        'Sunt complet implicat, în fiecare zi, indiferent de ceea ce fac.'
      ]
    },
    {
      label: 'Gestionează comportamentul',
      options: [
        'Adesea mă găsesc în necazuri sau acționând necorespunzător.',
        'Sub stres, uneori acționez în moduri pe care le regret mai târziu.',
        'Comportamentul meu este variabil, în funcție de situație.',
        'În general, gestionez lucrurile calm și mă mențin liniștit.',
        'Întotdeauna reușesc să acționez responsabil și să fac alegeri bune, chiar și când este dificil.'
      ]
    }
  ];
  