import { createContext, useContext, useState } from "react";
import { UserInfo, getUserInfo } from "../common/user";
import { useQuery } from "@tanstack/react-query";
import sql from "../common/sql";
import { UserEntity } from "../../../backend/src/shared/entity";

const UserContext = createContext<{
  user: UserInfo;
  setUser: (user: UserInfo) => void;
}>(null as any);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState(getUserInfo());
  const account = useQuery({
    queryKey: ['myAccountQuery', user.uuid, user.hashkey],
    queryFn: () => sql.myAccountQuery({ uuid: user.uuid }) as Promise<Array<UserEntity>>,
    enabled: !!user.uuid,
    refetchOnWindowFocus: false,
  });
  user.account = account.data?.[0];
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => {
  const { user, setUser } = useContext(UserContext);
  return { user, setUser: () => setUser(getUserInfo()) };
};
