import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import sql from '../../common/sql';
import { ForumEntity } from '../../../../backend/src/shared/entity';
import Lang from '../Lang';
import Loading from '../Loading/Loading';
import { Link } from 'react-router-dom';
import Fa from '../FontAwesome';
import { localeDateTime } from '../../common/utils';
import { useTranslate } from '../../common/lang';

const Forum = () => {
    const [topicSearch, setTopicSearch] = useState('');
    const translate = useTranslate();
    const data = useQuery({
        queryKey: ['forumTopicQuery', topicSearch],
        queryFn: () => sql.forumTopicQuery({ topicSearch }) as Promise<Array<ForumEntity>>
    });
    return (
        <div className='container'>
            <p className='lead d-flex justify-content-between'>
                <Lang text='Subiecte de forum' />
                <Link to='/forum-form' className='ms-3'><Fa duotone='message-plus fa-lg' /></Link>
            </p>
            <div className='position-relative'>
                <input type='text' value={topicSearch} onChange={e => setTopicSearch(e.target.value)} />
                <Fa duotone='search fa-lg position-absolute top-50 end-0 pe-2' />
            </div>
            <hr />
            {data.isLoading && <Loading />}
            {data.data?.map(forum => (
                <div key={forum.Id} className='row mb-4'>
                    <h6>
                        <Link to={`/forum-posts/${forum.Id}`}>{forum.Id}) {forum.Topic}</Link>
                    </h6>
                    <div dangerouslySetInnerHTML={{ __html: forum.Description }} className='text-muted'></div>
                    <small className='float-end'><i>{translate("postat de")} {forum.UserName}, {localeDateTime(forum.Date)}</i></small>
                </div>
            ))}
        </div>
    )
}

export default Forum