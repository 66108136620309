import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { useParams } from 'react-router-dom';
import sql from '../../common/sql';
import { FeedbackEntity } from '../../../../backend/src/shared/entity';
import FeedbackDetail from '../Feedback/FeedbackDetail';
import Title from '../Title';
import { Labels } from '../../common/lang';

const ForumPosts = () => {
    const { id = 0 } = useParams();
    const topic = useQuery({
        queryKey: ['forumEntityQuery', id],
        queryFn: () => sql.getEntity('dbo.Forum', +id)
    });
    const posts = useQuery({
        queryKey: ['forumPostsQuery', id],
        queryFn: () => sql.forumPostsQuery({ idForum: +id }) as Promise<Array<FeedbackEntity>>
    });

    return (
        <div>
            <Title text={topic.data?.Topic as Labels}></Title>
            <div dangerouslySetInnerHTML={{ __html: topic.data?.Description || "" }}></div>
            <FeedbackDetail idForum={+id} />
        </div>
    )
}

export default ForumPosts