import React, { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import sql from "../../common/sql";
import {
  UserEntity,
  PartnerEntity,
  MentoringEntity,
  CountryEntity,
} from "../../../../backend/src/shared/entity";
import StartCard from './StatCard';
import AddPartnerModal from './AddPartnerModal';
import UserTable from './UserTable';
import PartnerTable from './PartnerTable';
import MentoringTable from './MentoringTable';

const UserDashboard = () => {
  const [selectedType, setSelectedType] = useState<"role" | "partner" | "country" | "mentoring" | null>(null);
  const [selectedIdentifier, setSelectedIdentifier] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPartner, setNewPartner] = useState<PartnerEntity>({
    Id: 0,
    IdCountry: 0,
    Name: "",
    Website: "",
    Logo: "",
    Country: "",
  });

  const {
    data: usersData,
    isLoading: isLoadingUsers,
    error: errorUsers,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: ["usersQuery"],
    queryFn: () => sql.getEntity("dbo.User", -1) as any as Promise<UserEntity[]>,
    enabled: true,
  });

  const {
    data: partnersData,
    isLoading: isLoadingPartners,
    error: errorPartners,
    refetch: refetchPartners,
  } = useQuery({
    queryKey: ["partnersQuery"],
    queryFn: () => sql.getEntity("dbo.Partner", -1) as any as Promise<PartnerEntity[]>,
    enabled: true,
  });

  const { data: mentoringData, isLoading: isLoadingMentoring, error: errorMentoring } = useQuery({
    queryKey: ["mentoringQuery"],
    queryFn: () => sql.getEntity("dbo.Mentoring", -1) as any as Promise<MentoringEntity[]>,
    enabled: true,
  });

  const { data: countriesData, isLoading: isLoadingCountries, error: errorCountries } = useQuery({
    queryKey: ["countriesQuery"],
    queryFn: () => sql.getEntity("dbo.Country", -1) as any as Promise<CountryEntity[]>,
    enabled: true,
  });

  const mutation = useMutation(
    async (newPartner: PartnerEntity) => {
      await sql.saveEntity("dbo.Partner", newPartner);
    },
    {
      onSuccess: () => {
        setIsModalOpen(false);
        refetchPartners();
      },
      onError: (error) => {
        console.error("Error adding partner:", error);
        alert("Failed to add partner. Please try again.");
      },
    }
  );

  const handleConfirmEmail = async (user: UserEntity) => {
    if (user.EmailConfirmed) return;

    const updatedUser = { ...user, EmailConfirmed: true };

    try {
      await sql.saveEntity("dbo.User", updatedUser);
      await refetchUsers();
    } catch (error) {
      console.error("Error confirming email:", error);
      alert("Failed to confirm email. Please try again.");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewPartner((prev) => ({
      ...prev,
      [name]: name === "IdCountry" ? Number(value) : value,
    }));
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = countriesData?.find(country => country.Id === Number(e.target.value));
    if (selectedCountry) {
      setNewPartner((prev) => ({
        ...prev,
        IdCountry: selectedCountry.Id,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { Country, ...partnerData } = newPartner; // Exclude the Country field
    mutation.mutate(partnerData);
  };

  const toggleModal = () => {
    console.log("Modal toggled");
    setIsModalOpen(!isModalOpen);
  };

  const calculateRoleCounts = (users: UserEntity[] | undefined) => {
    if (!users) return { mentees: 0, mentors: 0, coordinators: 0, admins: 0 };

    const roleCounts = {
      mentees: 0,
      mentors: 0,
      coordinators: 0,
      admins: 0,
    };

    users.forEach((user) => {
      switch (user.Role) {
        case "Mentorat":
          roleCounts.mentees += 1;
          break;
        case "Mentor":
          roleCounts.mentors += 1;
          break;
        case "Coordonator":
          roleCounts.coordinators += 1;
          break;
        case "Admin":
          roleCounts.admins += 1;
          break;
        default:
          break;
      }
    });

    return roleCounts;
  };

  const roleCounts = calculateRoleCounts(usersData);

  const partnerMap = (partnersData || []).reduce((map, partner) => {
    map[partner.Id] = {
      name: partner.Name || "Unknown",
      country: partner.Country || "Unknown",
    };
    return map;
  }, {} as Record<number, { name: string; country: string }>);

  const userMap = (usersData || []).reduce((map, user) => {
    map[user.Id] = user.Name;
    return map;
  }, {} as Record<number, string>);

  const filteredUsers = selectedType === "role" && selectedIdentifier
    ? usersData?.filter((user) => user.Role === selectedIdentifier)
    : [];

  const filteredPartners = partnersData || [];
  const filteredMentoring = mentoringData || [];

  const uniqueCountries = new Set(partnersData?.map((partner) => partner.Country).filter((country) => country) || []);

  const handleViewAllClick = (type: "role" | "partner" | "country" | "mentoring", identifier: string | null = null) => {
    setSelectedType(type);
    setSelectedIdentifier(identifier);
  };

  if (isLoadingUsers || isLoadingPartners || isLoadingMentoring || isLoadingCountries) return <p>Loading...</p>;
  if (errorUsers || errorPartners || errorMentoring || errorCountries) {
    const errorMessage =
      (errorUsers as Error)?.message ||
      (errorPartners as Error)?.message ||
      (errorMentoring as Error)?.message ||
      (errorCountries as Error)?.message ||
      "An unknown error occurred";

    return <p>Error: {errorMessage}</p>;
  }

  return (
    <div className="user-dashboard">
      <div className="dashboard-container">
        <StartCard title="Relations" count={mentoringData?.length || 0} color="yellow" onViewAllClick={() => handleViewAllClick("mentoring")} />
        <StartCard title="Mentees" count={roleCounts.mentees} color="blue" onViewAllClick={() => handleViewAllClick("role", "Mentorat")} />
        <StartCard title="Mentors" count={roleCounts.mentors} color="orange" onViewAllClick={() => handleViewAllClick("role", "Mentor")} />
        <StartCard title="Coordinators" count={roleCounts.coordinators} color="green" onViewAllClick={() => handleViewAllClick("role", "Coordonator")} />
        <StartCard title="Admins" count={roleCounts.admins} color="gray" onViewAllClick={() => handleViewAllClick("role", "Admin")} />
        <StartCard title="Partners" count={partnersData?.length || 0} color="purple" onViewAllClick={() => handleViewAllClick("partner")} />
        <StartCard title="Countries" count={uniqueCountries.size} color="teal" onViewAllClick={() => handleViewAllClick("country")} />

        {selectedType === "partner" && (
          <button onClick={toggleModal} className="btn btn-primary add-partner-button">Add Partner</button>
        )}

        <AddPartnerModal
          isOpen={isModalOpen}
          newPartner={newPartner}
          onChange={handleChange}
          onCountryChange={handleCountryChange}
          onSubmit={handleSubmit}
          onClose={toggleModal}
          countries={countriesData || []}
        />
      </div>

      {selectedType === "partner" && (
        <div className="user-list">
          <PartnerTable partners={filteredPartners} />
        </div>
      )}

      {selectedType === "role" && selectedIdentifier && (
        <div className="user-list">
          <UserTable users={filteredUsers || []} partnerMap={partnerMap} onConfirmEmail={handleConfirmEmail} />
        </div>
      )}

      {selectedType === "mentoring" && (
        <div className="user-list">
          <MentoringTable mentoring={filteredMentoring} userMap={userMap} />
        </div>
      )}
    </div>
  );
};

export default UserDashboard;