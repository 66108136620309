import React from 'react'
import { MentoringEntity, MentoringSessionEntity } from '../../../../backend/src/shared/entity';
import Fa from '../FontAwesome';
import { Link } from 'react-router-dom';

const MentoringSessionProgress = ({ mentoring = {} as MentoringEntity, sessions = [] as MentoringSessionEntity[] }) => {
    return (
        <p>
            {
                ([...(Array(mentoring?.Sessions || 0) as []).keys()].map(i => (
                    <span key={i}>
                        {!!i && <Fa duotone='arrow-right' />}
                        <Link to={`/mentoring-calendar/${mentoring.Id}/${i}`}>
                            <MentoringSessionProgressElement index={i + 1} session={sessions?.[i]} />
                        </Link>
                    </span>
                )))
            }
        </p>
    )
}

export default MentoringSessionProgress


const MentoringSessionProgressElement = ({ index = 0, session = {} as MentoringSessionEntity }) => {
    console.log(`#${index}`, session);
    const color = !session.Id ? 'secondary' : (session.MenteeAccepted && session.MenteeAccepted ? 'success' : 'danger');
    const isCurrent = session.Id && (new Date(session.DateProposal).getTime() > new Date().getTime());
    return <span className={`badge rounded-pill text-bg-${isCurrent ? 'warning' : color} mx-1`}>#{index}</span>;
}