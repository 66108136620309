import Modal from "./components/Modal";
import { Toaster } from "sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router from "./components/Router";
import { LanguageProvider } from "./hooks/useLang";
import { UserProvider } from "./hooks/useUser";
import { WebSocketProvider } from "./hooks/useWebSocket";
import "./App.scss";
import { DataProvider } from "./hooks/useData";
import { NotificationProvider } from "./hooks/useNotification";

const queryClient = new QueryClient();
const App = () => {
  if (window.location.pathname === "/index.html") {
    window.location.pathname = "/";
  }
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <NotificationProvider>
          <UserProvider>
            <DataProvider>
              <WebSocketProvider>
                <LanguageProvider>
                  <Router />
                  <Toaster
                    // closeButton
                    richColors
                    position="top-right"
                    theme="system"
                    expand
                  />
                  <Modal />
                </LanguageProvider>
              </WebSocketProvider>
            </DataProvider>
          </UserProvider>
        </NotificationProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
