import React from 'react'
import Title from '../Title'
import { useTranslate } from '../../common/lang';

const MentoringIs = () => {
    const translate = useTranslate();
    return (
        <div>
            <Title text='Ce înseamnă mentorarea?' />
            <p>
                {translate("Mentoratul este orice formă de învățare socială în care cineva cu mai multă experiență (un mentor) ajută la ghidarea învățării și creșterii cuiva cu mai puțină experiență (un mentorat). Este o relație de învățare care îi ajută pe oameni să își asume propria dezvoltare, să-și elibereze potențialul și să obțină rezultate pe care le prețuiesc.")}
            </p>
            <p>
                {translate("Există multe definiții ale mentoratului. Cu toate acestea, definiția cea mai succintă este atunci când cineva își împărtășește cunoștințele, abilitățile și experiența pentru a ajuta o altă persoană să progreseze. Acest progres ar putea fi profesional sau personal, deși, având în vedere echilibrul muncă/viață, este adesea luată în considerare încrucișarea dintre cele două. Mentorul acționează ca un consilier de încredere și un model de urmat, împărtășindu-și expertiza și ajutându-l pe mentor să-și dezvolte abilitățile, cunoștințele și abilitățile.")}
            </p>
            <p>
                {translate("Mentoratul este o relație. Este o relație între mentor și mentorat. Mentoratul este definit ca o relație unu-la-unu în care o persoană cu mai multă experiență acordă în mod voluntar timp pentru a preda, sprijini și încuraja pe altul. Conceptul de mentorat se referă la sprijinul emoțional și la îndrumarea oferite de obicei de un individ matur unei persoane mai tinere. Există multă perfecționare a definiției în literatura recentă despre mentorat. Mentoratul transmite cunoștințele despre subiecte, facilitează dezvoltarea personală, încurajează alegerile înțelepte și ajută mentoratul să facă tranziții.")}                
            </p>
        </div>
    )
}

export default MentoringIs