import { Labels, useTranslate } from "../common/lang";

const ErrorBox = ({ children }: any) => {
  const translate = useTranslate();
  if (!children) return <></>;

  let error = "";
  if (children?.message) error = children.message;
  else if (typeof children === "string") {
    try {
      error = JSON.parse(children).message;
    } catch (e) { }
  }
  if (error) {
    let [message, value = ""] = error.split("«:»");
    error = translate(message as Labels).replaceAll('@timeout', value).replace('@email', value);
  }
  return (
    <fieldset>
      {children && (
        <article className="border border-danger-subtle rounded p-2">
          {typeof children === "string" || error ? (
            <small
              style={{ overflowWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: error || children }}
            ></small>
          ) : (
            <small>{children}</small>
          )}
        </article>
      )}
    </fieldset>
  );
};

export default ErrorBox;
