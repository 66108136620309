import { Capacitor } from "@capacitor/core";
import { PushNotifications, PushNotificationSchema } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { createContext, useContext, useEffect, useState } from "react";

const NotificationContext = createContext<{
  notifications: PushNotificationSchema[];
  token: string | undefined;
  error: any | undefined;
  removeNotification: (notification: PushNotificationSchema) => void;
}>(null as any);

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [error, setError] = useState<any | undefined>(undefined);
  const [notifications, setNotifications] = useState<PushNotificationSchema[]>([]);

  const removeNotification = (notification: PushNotificationSchema) => {
    setNotifications(prev => prev.filter(n => n.id !== notification.id));
    PushNotifications.removeDeliveredNotifications({ notifications: [notification] });
  }
  const getDeliveredNotifications = () => {
    setTimeout(() =>
      PushNotifications
        .getDeliveredNotifications()
        .then(({ notifications }) => notifications.length && setNotifications(prev => [...prev, ...notifications]))
        .finally(() => PushNotifications.removeAllDeliveredNotifications())
      , 200); 
  }
  useEffect(() => {
    PushNotifications.addListener('registration', async ({ value }) => {
      try {
        if (Capacitor.getPlatform() === 'ios') {
          // Get FCM token instead the APN one returned by Capacitor
          value = (await FCM.getToken()).token; // FCM token for iOS
        }
      } catch (error) {
        console.error(error);
      }
      console.log('Push registration token:', value);
      // window.alert(JSON.stringify(value));
      setToken(value);
    })

    PushNotifications.addListener('registrationError', error => setError(error));
    PushNotifications.addListener('pushNotificationReceived', notification => setNotifications(prev => [...prev, notification]));
    registerNotifications()
      .then(getDeliveredNotifications);
  }, []);
  return (
    <NotificationContext.Provider value={{ token, error, notifications, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotification = () => {
  return useContext(NotificationContext);
};

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
}
