import { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import sql from "../common/sql";
import { CountryEntity, PartnerEntity } from "../../../backend/src/shared/entity";

const DataContext = createContext<{
  Country: CountryEntity[] | undefined;
  Partner: PartnerEntity[] | undefined;
  isLoading: boolean;
  isRefetching: boolean;
  error: unknown;
  refetch: () => void;
}>(null as any);

export function DataProvider({ children }: { children: React.ReactNode }) {
  const Country = useQuery({
    queryKey: ['countryQuery'],
    queryFn: () => sql.countryQuery() as Promise<Array<CountryEntity>>,
    refetchOnWindowFocus: false,
  });
  const Partner = useQuery({
    queryKey: ['partnerQuery'],
    queryFn: () => sql.partnerQuery() as Promise<Array<PartnerEntity>>,
    refetchOnWindowFocus: false,
  });
  const data = {
    Country: Country.data,
    Partner: Partner.data,
    isLoading: Country.isLoading || Partner.isLoading,
    isRefetching: Country.isFetching || Partner.isFetching,
    error: Country.error || Partner.error,
    refetch: () => {
      Country.refetch();
      Partner.refetch();
    },
  };

  return (
    <DataContext.Provider value={data}>
      {children}
    </DataContext.Provider>
  );
}

export const useData = () => {
  return useContext(DataContext)
};
