import React from 'react'
import { useUser } from '../../hooks/useUser';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { Link } from 'react-router-dom';
import { MentoringEntity, UserEntity } from '../../../../backend/src/shared/entity';
import Loading from '../../components/Loading/Loading';
import { useTranslate } from '../../common/lang';
import FaRole from '../../components/Icons/FaRole';
import Title from '../../components/Title';

const MentoringRelationship = () => {
    const translate = useTranslate();
    const { user } = useUser();
    const mentoring = useQuery({
        queryKey: ['mentoringRelationshipQuery', user.account?.Id],
        queryFn: () => sql.mentoringRelationshipQuery({ idCoordinator: user.account?.Id }) as Promise<[MentoringEntity[], UserEntity[], UserEntity[]]>,
        enabled: !!user.account?.Id
    });
    if (mentoring.isLoading) return <Loading />
    const mentoringTitle = (m: MentoringEntity) => {
        const mentee = mentoring.data && mentoring.data[1].find(u => u.Id === m.IdMentee);
        const mentor = mentoring.data && mentoring.data[2].find(u => u.Id === m.IdMentor);
        return (<><FaRole role="Mentor" />{mentor?.Name} — <FaRole role="Mentorat" />{mentee?.Name}</>);
    }
    const unmentored = mentoring.data && mentoring.data[1].filter((u: UserEntity) => !mentoring.data[0].find((m: MentoringEntity) => m.IdMentee === u.Id));
    return (
        <>
            <ul className='nav flex-column'>
                <li className='text-muted'><Title text='Persoane nementorate'/></li>

                {unmentored?.map((u: UserEntity) => (
                    <li key={u.Id} className='py-2'>
                        <Link to={`/coordinator/mentoring-relationship/0/${u.Id}`}><FaRole role="Mentorat" /> {u.Name}</Link>
                    </li>
                ))}
            </ul>
            <hr />
            <ul className='nav flex-column'>
                <li className='text-center text-muted'><h5>{translate('Mentorare în curs')}</h5></li>
                {mentoring.data && mentoring.data[0].map((m: MentoringEntity) => (
                    <li key={m.Id} className='py-2'>
                        <Link to={`/coordinator/mentoring-relationship/${m.Id}/0`}>{mentoringTitle(m)}</Link>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default MentoringRelationship