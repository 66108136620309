import React from 'react'
import { useParams } from 'react-router-dom'
import Title from './Title';
import { Labels } from '../common/lang';
const Iframe = ({ file = "" }) => {
  const { file: fileParam, name: nameParam } = useParams();
  const filename = window.location.origin + (file || fileParam);
  console.log({ nameParam, fileParam, filename });
  return (
    <>
      {!file && fileParam && <Title text={(nameParam as Labels) || "Pagina anterioară"} />}
      <iframe src={filename} width="100%" height="600px" title="pdf"></iframe>
    </>
  )
}

export default Iframe