import { Link } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import Avatar from './Avatar';
import { ChangeEvent, MouseEventHandler, useRef, useState } from 'react';
import Button from './Button';
import Lang from './Lang';
import Fa from './FontAwesome';
import sql from '../common/sql';
import Loading from './Loading/Loading';
import ErrorBox from './ErrorBox';
import Back from './Back';

const AccountAvatar = () => {
    const { user, setUser } = useUser();
    const inputRef = useRef<HTMLInputElement>(null);
    const [avatar, setAvatar] = useState('');
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState('');
    if (!user.email) return null;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        console.log(e.target.files);
        if (!file) return;
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
            setAvatar(e.target?.result as string);
        });
        reader.readAsDataURL(file);
    };

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();
        inputRef.current?.click();
    };

    const saveAvatar = async () => {
        setError('');
        setSaving(true);
        sql.saveEntity('dbo.User', { Id: user.account?.Id, Avatar: avatar })
            .then(() => [setUser(), setAvatar('')])
            .catch(setError)
            .finally(() => setSaving(false));
    }
    if (saving) return <Loading />;
    return (
        <div className="row">
            <div className="col-12">
                <Back small />
            </div>
            <div className="col text-center mt-5">
                <Link to="" onClick={handleClick}>
                    <Avatar src={avatar || user.account?.Avatar} large />
                </Link>
                <input type="file" accept="image/*" onChange={handleChange} ref={inputRef} style={{ display: 'none' }} />
            </div>
            <ErrorBox error={error} />
            <div className='px-5 pt-5'>
                <Button color='btn-primary' disabled={!avatar} onClick={e => [e.preventDefault(), saveAvatar()]}>
                    <Fa solid='save' /><Lang text='Salvează' />
                </Button>
            </div>
        </div>
    );
}

export default AccountAvatar