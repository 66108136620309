import { useState } from "react";
import { loginUser } from "../../common/user";
import { useUser } from "../../hooks/useUser";
import { useParams } from "react-router-dom";
import ErrorBox from "../../components/ErrorBox";
import Lang from "../../components/Lang";
import Loading from "../../components/Loading/Loading";
import { useQuery } from "@tanstack/react-query";
import Button from "../../components/Button";

const ValidateEmailPage = () => {
  const { token } = useParams();
  const { setUser } = useUser();
  const [completed, setCompleted] = useState(false);
  const { isLoading, data, error } = useQuery({
    queryKey: ["ValidateEmailPage", token],
    queryFn: () => loginUser({ email: token || "" }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isLoading) return <Loading />;
  if (!error && data && !completed) {
    setUser();
    setCompleted(true);
  }

  return (
    <form>
      {error
        ? (
          <div>
            <h5 className="card-title">
              <Lang text="Link activare cont" />
            </h5>
            <ErrorBox>{error}</ErrorBox>
          </div>)
        : (<div>
          <h5 className="card-title">
            <Lang text="Te-ai înregistrat cu succes!" />
          </h5>
          <Button to="/" color="btn-outline-warning" text="Închide" />
          <hr />
        </div>
        )}
    </form>
  );
};

export default ValidateEmailPage;
