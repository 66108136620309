// import { useLocation } from "react-router-dom";
import MottoImage from "./MottoImage";
import { useUser } from "../hooks/useUser";
import LangPicker from "./LangPicker";
import BugReport from "./BugReport";
// import ReactGA from "react-ga4";
// import { useEffect } from "react";

// ReactGA.initialize('G-JDEY5XDXCF');

const Header = () => {
  // const location = useLocation();
  const { user } = useUser();
  if (!user.email) return <LangPicker/>;

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  // }, [location]);

  return (
    <nav className="navbar fixed-top bg-light pe-2">
        <MottoImage />
        <BugReport
              userInfo={{
                userId: "1",
                username: "john_doe",
                email: "john@example.com",
              }}
              page="Dashboard"
              language="English"
            />
    </nav>
  );
};

export default Header;