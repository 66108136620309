const Fa = ({
  size = "" as "2xs" | "xs" | "sm" | "lg" | "xl" | "2xl",
  solid = "",
  light = "",
  regular = "",
  brands = "",
  duotone = "",
  thin = "",
  fw = undefined,
  onClick = ()=>{},
}) => {
  const style = brands
    ? "brands"
    : solid
    ? "solid"
    : light
    ? "light"
    : regular
    ? "regular"
    : duotone
    ? "duotone"
    : thin
    ? "thin"
    : "regular";
  const icon =
    (fw ? "fa-fw " : "") +
    (size ? `fa-${size} fa-` : "fa-") +
    (brands || solid || light || regular || duotone || thin);

  return <i className={`fa-${style} ${icon}`} onClick={onClick}/>;
};

export default Fa;
