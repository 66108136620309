import { createContext, useContext, useState } from "react";
import { Language, languages } from "../common/lang";
import { useUser } from "./useUser";
import { UserInfo } from "../common/user";
import sql from "../common/sql";

const LanguageContext = createContext<{
  lang: Language;
  setLang: (lang: Language) => void;
}>(null as any);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [lang, setLang] = useState<Language>(getUserLang());
  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLang = () => {
  const { lang, setLang } = useContext(LanguageContext);
  const { user } = useUser();
  return { lang, setLang: (lang: Language) => setUserLang(lang, user).then(() => setLang(getUserLang())) };
};

export const getUserLang = () =>
  (JSON.parse(localStorage.getItem("userInfo") || '{}').lang || browserLanguage()) as Language;

export const setUserLang = async (lang: Language, user: UserInfo) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
  userInfo.lang = lang;
  if (lang && user.account?.Id) {
    sql.saveEntity("dbo.User", { Id: user.account.Id, Language: lang }).catch(console.error);
  }
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};

const browserLanguage = () => {
  const language = (window.navigator.language || "en-US").split("-")[0] as Language;
  return languages.find(lang => lang === language) || "en";
}