import React from 'react'
import Lang from '../Lang'
import { Labels } from '../../common/lang'

const FeedbackParent = ({ message = "", title='Ca răspuns la' as Labels }) => {
    return (
        <div>
            <i className='fw-semibold'><Lang text={title} />:</i>
            <div className='border border-secondary border-3 border-top-0 border-end-0 border-bottom-0 ps-3'>
                <small className='text-muted'>
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                </small>
            </div>
        </div>
    )
}

export default FeedbackParent