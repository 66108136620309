import React from 'react';
import { CountriesSymbol, MenteeResourceLang, MentorResourceLang, useResourceTranslate } from '../../common/lang';
import { useUser } from '../../hooks/useUser';
import { MenteeResourceLink, MentorResourceLink } from '../../common/resources';
import { useLang } from '../../hooks/useLang';
import Iframe from '../Iframe';
import { Link } from 'react-router-dom';
import Lang from '../Lang';
import Button from '../Button';
import Fa from '../FontAwesome';

const ResourcesLinks = ({ view, setView }: any) => {
    const translate = useResourceTranslate();
    const { user } = useUser();
    const { lang } = useLang();
    const [scrollY, setScrollY] = React.useState(0);
    const resources = Object.keys(user.role === "Mentorat" ? MenteeResourceLang : MentorResourceLang);
    const linkOf = (r: string) => {
        const source = user.role === "Mentorat" ? MenteeResourceLink : MentorResourceLink;
        const base = user.role === "Mentorat" ? 'Mentee' : 'Mentor';
        const country = CountriesSymbol[lang];
        const filename = (source as any)[r]["English"].filename.replace('.pdf', '.html');
        return (`/resources/${base}/${r}/${country}/${filename}`);
    };
    const saveScroll = () => {
        setScrollY(window.scrollY);
        window.scrollTo(0, 0);
    }
    const restoreScroll = () => {
        setTimeout(() => window.scrollTo(0, scrollY), 100);
    }
    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            {view ? <>
                <Button onClick={(e) => [setView(null), restoreScroll(), e.preventDefault()]}>
                    <Fa duotone="circle-arrow-left fa-xl" /> <Lang text='Pagina anterioară' />
                </Button>
                <div className="btn print-button">
                    <Button onClick={handlePrint}>
                        <Fa duotone="print fa-xl" /> <Lang text='Printeaza' />
                    </Button>
                </div>
                <Iframe file={linkOf(view)} />
            </>
                : <ul className='nav flex-column'>
                    {resources.map(r => (
                        <li key={r} className='nav-link'>
                            <Link to='' onClick={e => [e.preventDefault(), setView(r), saveScroll()]}>{translate(r as any)}</Link>
                        </li>
                    ))}
                </ul>
            }
        </>
    )
}

export default ResourcesLinks;