import React from 'react'
import Title from '../Title'

const ContactUs = () => {
    return (
        <div>
            <Title text='Contactează-ne' />
            <p>
                Beyond the Horizon ISSG
                Davincilaan 1, 1930 Zaventem
                Belgium
            </p>
            <a href="mailto:info@behorizon.org">info@behorizon.org</a>
            <br />
            <a href="tel:+32 (0)2 801 13 58">+32 (0)2 801 13 58</a>
        </div>
    )
}

export default ContactUs