import React from 'react';
import { UserEntity } from '../../../../backend/src/shared/entity';

interface UserTableProps {
  users: UserEntity[];
  partnerMap: Record<number, { name: string; country: string }>;
  onConfirmEmail: (user: UserEntity) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users, partnerMap, onConfirmEmail }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Email Confirmed</th>
          <th>Last Login</th>
          <th>Partner</th>
          <th>Country</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          const partnerInfo = partnerMap[user.IdPartner] || { name: "Unknown", country: "Unknown" };
          return (
            <tr key={user.Id}>
              <td>{user.Name}</td>
              <td>{user.Email}</td>
              <td>{user.EmailConfirmed ? "Yes" : "No"}</td>
              <td>{user.LastLoginStamp ? new Date(user.LastLoginStamp).toLocaleString() : "N/A"}</td>
              <td>{partnerInfo.name}</td>
              <td>{partnerInfo.country}</td>
              <td>
                <button
                  onClick={() => onConfirmEmail(user)}
                  disabled={user.EmailConfirmed}
                  style={{
                    backgroundColor: user.EmailConfirmed ? "#ccc" : "#28a745",
                    color: "#fff",
                    padding: "5px 10px",
                    cursor: user.EmailConfirmed ? "not-allowed" : "pointer",
                  }}
                >
                  {user.EmailConfirmed ? "Confirmed" : "Confirm Email"}
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default UserTable;