import { MentoringSessionEntity } from "../../../backend/src/shared/entity";
import FaRole from "../components/Icons/FaRole";
import { UserRole } from "./user";

let DDD = ["Duminică", "Luni", "Marți", "Miercuri", "Joi", "Vineri", "Sâmbătă"];
let DD = ["Dum", "Lun", "Mar", "Mie", "Joi", "Vin", "Sâm"];

let MMM = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie",
    "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"];
let MM = ["Ian", "Feb", "Mar", "Apr", "Mai", "Iun", "Iul", "Aug", "Sep", "Oct", "Noi", "Dec"];

export function formatDate(value: null | string | Date, format: "short" | "long" | "text" = "short") {
    if (!value) return "";
    let date = value instanceof Date ? value : new Date(value);
    return format === "short" ?
        date.toLocaleDateString() :
        format === "long" ?
            date.toLocaleString() : textOfDate(date);
}

export function textOfDate(value: Date | string, format = "DDD, dd MM YYYY"): string {
    if (!value) return "";
    let date = value instanceof Date ? value : new Date(value);
    let totay = new Date();
    let yesterday = new Date(totay.getFullYear(), totay.getMonth(), totay.getDate() - 1);
    let tomorrow = new Date(totay.getFullYear(), totay.getMonth(), totay.getDate() + 1);
    if (date.toDateString() === totay.toDateString()) format = format.replace('DDD', "azi").replace('DD', "azi");
    if (date.toDateString() === yesterday.toDateString()) format = format.replace('DDD', "ieri").replace('DD', "ieri");
    if (date.toDateString() === tomorrow.toDateString()) format = format.replace('DDD', "mâine").replace('DD', "mâine");
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    format = format
        .replace("DDD", DDD[date.getDay()]).replace("DD", DD[date.getDay()])
        .replace("MMMM", MMM[month]).replace("MM", MM[month])
        .replace("YYYY", year.toString()).replace("YY", year.toString().substring(2))
        .replace("dd", day.toString()).replace("M", month.toString()).replace("Y", year.toString());
    return format;
}

export function localeDateTime(date: Date | string) {
    let d = new Date(date);
    return d.toLocaleDateString() + " " + d.toLocaleTimeString().substring(0, 5);
}


export const filterBySelectedDate = (selectedDate:Date, session: MentoringSessionEntity) => {
    // return true;
    const date = new Date(session.DateProposal);
    return date.toDateString() === (selectedDate as Date).toDateString();
}
export const roomateFor = (role:UserRole, session: MentoringSessionEntity) => {
    console.log(role, session); 
    if (role === 'Mentor') return <><FaRole role='Mentorat' />{session.Mentee}</>;
    if (role === 'Coordonator') return <><FaRole role='Mentor' />{session.Mentor} » <FaRole role='Mentorat' />{session.Mentee}</>
    return <><FaRole role='Mentor' />{session.Mentor}</>;
}
