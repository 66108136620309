import { Link, useNavigate } from "react-router-dom";
import Lang from "./Lang";
import { UserRole, logoutUser } from "../common/user";
import { useUser } from "../hooks/useUser";
import Button from "./Button";
import LangPicker from "./LangPicker";
import { useTranslate } from "../common/lang";
import Avatar from "./Avatar";
import Fa from "./FontAwesome";
import { useQuery } from "@tanstack/react-query";
import sql from "../common/sql";
import React from "react";
import { useNotification } from "../hooks/useNotification";

const Account = () => {
  const { user, setUser } = useUser();
  const { notifications } = useNotification();
  const navigate = useNavigate();
  const translate = useTranslate();
  const feedback = useQuery({
    queryKey: ['unreadFeedbackQuery', user.uuid],
    queryFn: () => sql.unreadFeedbackQuery({ uuid: user.uuid }) as Promise<Array<{ Unread: number, Count: number }>>,
    enabled: !!user.account?.Id,
  });

  return (
    <>
      <div className="row px-3">
        <div className="col">
          <Link to="/account-avatar" className="float-start">
            <Avatar src={user.account?.Avatar} />
            {user.name}
          </Link>
        </div>
        <div className="col">
          <Link to="/" className="float-end" onClick={e => [e.preventDefault(), logoutUser().then(setUser).then(() => navigate('/'))]}>
            <i className="fa-solid fa-arrow-right-from-arc text-danger me-2"></i>
            <Lang text="Deconectare" />
          </Link>
          {/* <Link to="/notification" className="float-end">
            <Fa duotone='bell me-2' /> <Lang text="Notificări" />
          </Link> */}
        </div>
        <div className="position-relative mb-2 pt-3">
          {user.account?.Email}
          <LangPicker />
        </div>
        <div className="col-12 mt-4">
          <Button to="/account-avatar">{translate("Setări cont")}</Button>
          <Button to="/account-password">{translate("Schimbă parola")}</Button>
          {user.role === "Admin" &&
            <section>
              <Button to="/admin/approve-coordinator" color='btn-outline-primary'>{translate("Validare coordonatori")}</Button>
              {/* <Button to="/coordinator/team-members" color='btn-outline-primary'>{translate("Gestiune echipe mentorare")}</Button> */}
            </section>
          }
          {user.role === "Coordonator" && user.account?.Approved &&
            <section>
              <Button to="/sessions" color='btn-outline-primary'>{translate("Gestiune echipe mentorare")}</Button>
            </section>
          }
          {(["Mentorat", "Mentor"] as UserRole[]).includes(user.role) &&
            <section>
              <Button to="/feedback" color='btn-outline-primary'>
                <span className="position-relative pe-3">
                  <Fa duotone='paper-plane me-2' />
                  {translate("Vizualizare observație")}
                  {!!feedback.data?.[0]?.Unread && <span className="badge bg-danger rounded-pill position-absolute top-0 start-100 translate-middle">{feedback.data?.[0]?.Unread}</span>}
                </span>
              </Button>
            </section>
          }
          {!!notifications.length &&
            <section>
              <Button to="/notification" color='btn-outline-primary'>
                <span className="position-relative pe-3">
                  <Fa duotone='bell me-2' />
                  {translate("Notificări")}
                  <span className="badge bg-danger rounded-pill position-absolute top-0 start-100 translate-middle">{notifications.length}</span>
                </span>
              </Button>
            </section>
          }
          {/* link catre forum */}
          <section>
            <Button to="/forum" color='btn-outline-primary'>
              <span className="position-relative pe-3">
                <Fa duotone='comments me-2' />
                {translate("Forum")}
              </span>
            </Button>
          </section>
          {(["Admin"] as UserRole[]).includes(user.role) &&
          <section>
            <Button to="/adminpanel" color='btn-outline-primary'>
              <span className="position-relative pe-3">
                <Fa duotone='cog me-2' />
                Admin Panel
              </span>
            </Button>
          </section>}
          {(["Admin","Mentorat", "Mentor","Coordonator"] as UserRole[]).includes(user.role) &&
          <section>
            <Button to="/mood-ring-graph" color='btn-outline-primary'>
              <span className="position-relative pe-3">
                <Fa duotone='chart-line me-2' />
                Mood Ring Graph
              </span>
            </Button>
          </section>}
        </div>
      </div>
    </>
  );
};

export default Account;
