import React from 'react'
import ResourcesLinks from './ResourcesLinks';
import Title from '../Title';

const Resources = () => {
    const [view, setView] = React.useState<any>();
    return (
        <div className='row'>
            {!view && <Title text='Resurse' />}
            <div className="col-12 px-4">
                <ResourcesLinks view={view} setView={setView} />
            </div>
        </div>
    )
}

export default Resources
