import React from 'react';
import { PartnerEntity, CountryEntity } from '../../../../backend/src/shared/entity';

interface AddPartnerModalProps {
  isOpen: boolean;
  newPartner: PartnerEntity;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCountryChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  onClose: () => void;
  countries: CountryEntity[];
}

const AddPartnerModal: React.FC<AddPartnerModalProps> = ({ isOpen, newPartner, onChange, onCountryChange, onSubmit, onClose, countries }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-form">
      <div className="modal-content">
        <h3 className="text-center mb-4">Add New Partner</h3>
        <form onSubmit={onSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="country">Country</label>
            <select
              id="country"
              name="Country"
              value={newPartner.IdCountry}
              onChange={onCountryChange}
              className="form-control"
              required
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country.Id} value={country.Id}>
                  {country.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="Name"
              value={newPartner.Name}
              onChange={onChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="website">Website</label>
            <input
              type="url"
              id="website"
              name="Website"
              value={newPartner.Website}
              onChange={onChange}
              className="form-control"
            />
          </div>
         
          <div className="text-center">
            <button type="submit" className="btn btn-success me-2">Save Partner</button>
            <button type="button" onClick={onClose} className="btn btn-danger">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPartnerModal;