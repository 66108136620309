import { useLang } from "../hooks/useLang";
import { labels } from "./labels";

export const CountriesSymbol = {
  "nl": "Dutch",
  "en": "English",
  "fr": "French",
  "el": "Greek",
  "it": "Italian",
  "pt": "Portuguese",
  "ro": "Romanian",
  "de": "German",
  "es": "Spanish",
};

export const languages: Array<keyof typeof CountriesSymbol> = ["ro", "en", "fr", "it", "pt", "el", "nl", "de", "es"];

export type Language = typeof languages[number];

export type Labels = keyof typeof labels;

export const useTranslate = () => {
  const { lang } = useLang();
  return (label: Labels) =>
    lang === "ro"
      ? label
      : ((labels[label] && (labels as any)[label][lang]) || label as string);
};

export const useLabels = () => {
  return labels;
};

export const MentorResourceLang = {
  "Affirmation Session": {
    "en": "Affirmation Session",
    "nl": "Bevestigingssessie",
    "fr": "Séance d'affirmation",
    "el": "Συνεδρία επιβεβαίωσης",
    "it": "Sessione di affermazione",
    "pt": "Sessão de afirmação",
    "ro": "Sesiune de afirmare",
    "de": "Bestätigungssitzung",
    "es": "Sesión de afirmación"
  },
  "Assessment Tool": {
    "en": "Assessment Tool",
    "nl": "Beoordelingsinstrument",
    "fr": "Outil d'évaluation",
    "el": "Εργαλείο αξιολόγησης",
    "it": "Strumento di valutazione",
    "pt": "Ferramenta de avaliação",
    "ro": "Instrument de evaluare",
    "de": "Bewertungswerkzeug",
    "es": "Herramienta de evaluación"
  },
  "Dealing with Conflicts and Difficult Behaviors": {
    "en": "Dealing with Conflicts and Difficult Behaviors",
    "nl": "Omgaan met conflicten en moeilijk gedrag",
    "fr": "Gérer les conflits et les comportements difficiles",
    "el": "Αντιμετώπιση συγκρούσεων και δύσκολων συμπεριφορών",
    "it": "Gestione dei conflitti e dei comportamenti difficili",
    "pt": "Lidando com Conflitos e Comportamentos Difíceis",
    "ro": "Confruntarea cu conflicte și comportamente dificile",
    "de": "Umgang mit Konflikten und schwierigen Verhaltensweisen",
    "es": "Lidiando con conflictos y comportamientos difíciles"
  },
  "Engaging in Change Talk": {
    "en": "Engaging in Change Talk",
    "nl": "Betrokken bij verandering",
    "fr": "Engager une discussion sur le changement",
    "el": "Συμμετοχή σε συζήτηση για την αλλαγή",
    "it": "Impegnarsi nel discorso sul cambiamento",
    "pt": "Envolvendo-se na Conversa sobre Mudança",
    "ro": "Angajarea în discuții despre schimbare",
    "de": "Einbeziehung in Veränderungsgespräche",
    "es": "Participando en conversaciones de cambio"
  },
  "Mindful Communication": {
    "en": "Mindful Communication",
    "nl": "Bewuste communicatie",
    "fr": "Communication consciente",
    "el": "Ενσυνείδητη επικοινωνία",
    "it": "Comunicazione consapevole",
    "pt": "Comunicação Consciente",
    "ro": "Comunicare conștientă",
    "de": "Achtsame Kommunikation",
    "es": "Comunicación consciente"
  },
  "Mock Interview": {
    "en": "Mock Interview",
    "nl": "Simulatie-interview",
    "fr": "Entretien simulé",
    "el": "Εικονική συνέντευξη",
    "it": "Intervista simulata",
    "pt": "Entrevista Simulada",
    "ro": "Interviu simulat",
    "de": "Probeanruf",
    "es": "Entrevista simulada"
  },
  "Mock MI": {
    "en": "Mock MI",
    "nl": "Simulatie MI",
    "fr": "Simulation MI",
    "el": "Εικονική MI",
    "it": "Simulazione MI",
    "pt": "MI Simulado",
    "ro": "MI simulat",
    "de": "Simulierte MI",
    "es": "MI simulada"
  },
  "Reflection to Change Talk": {
    "en": "Reflection to Change Talk",
    "nl": "Reflectie op verandering",
    "fr": "Réflexion sur le discours de changement",
    "el": "Αντανάκλαση στη συζήτηση για την αλλαγή",
    "it": "Riflessione sul discorso del cambiamento",
    "pt": "Reflexão sobre a Conversa de Mudança",
    "ro": "Reflecție pentru discuții despre schimbare",
    "de": "Reflexion auf Veränderungsgespräche",
    "es": "Reflexión sobre la conversación de cambio"
  },
  "Simple Reflection": {
    "en": "Simple Reflection",
    "nl": "Eenvoudige reflectie",
    "fr": "Réflexion simple",
    "el": "Απλή αντανάκλαση",
    "it": "Riflessione semplice",
    "pt": "Reflexão Simples",
    "ro": "Reflecție simplă",
    "de": "Einfache Reflexion",
    "es": "Reflexión simple"
  }
};

export const MenteeResourceLang = {
  "ACT_ Recognizing Values and Ideas": {
    "en": "ACT_ Recognizing Values and Ideas",
    "nl": "ACT_ Waarden en ideeën herkennen",
    "fr": "ACT_ Reconnaître les valeurs et les idées",
    "el": "ACT_ Αναγνώριση αξιών και ιδεών",
    "it": "ACT_ Riconoscere valori e idee",
    "pt": "ACT_ Reconhecendo Valores e Ideias",
    "ro": "ACT_ Recunoașterea valorilor și a ideilor",
    "de": "ACT_ Erkennen von Werten und Ideen",
    "es": "ACT_ Reconociendo valores e ideas"
  },
  "Affirmation Session": {
    "en": "Affirmation Session",
    "nl": "Bevestigingssessie",
    "fr": "Séance d'affirmation",
    "el": "Συνεδρία επιβεβαίωσης",
    "it": "Sessione di affermazione",
    "pt": "Sessão de afirmação",
    "ro": "Sesiune de afirmare",
    "de": "Bestätigungssitzung",
    "es": "Sesión de afirmación"
  },
  "Assessment Tool": {
    "en": "Assessment Tool",
    "nl": "Beoordelingsinstrument",
    "fr": "Outil d'évaluation",
    "el": "Εργαλείο αξιολόγησης",
    "it": "Strumento di valutazione",
    "pt": "Ferramenta de avaliação",
    "ro": "Instrument de evaluare",
    "de": "Bewertungswerkzeug",
    "es": "Herramienta de evaluación"
  },
  "Breathing Sessions": {
    "en": "Breathing Sessions",
    "nl": "Ademsessies",
    "fr": "Séances de respiration",
    "el": "Συνεδρίες αναπνοής",
    "it": "Sessioni di respirazione",
    "pt": "Sessões de Respiração",
    "ro": "Sesiuni de respirație",
    "de": "Atemübungen",
    "es": "Sesiones de respiración"
  },
  "Building a CV": {
    "en": "Building a CV",
    "nl": "Een CV opbouwen",
    "fr": "Construire un CV",
    "el": "Δημιουργία βιογραφικού",
    "it": "Costruire un CV",
    "pt": "Construindo um CV",
    "ro": "Crearea unui CV",
    "de": "Erstellung eines Lebenslaufs",
    "es": "Creando un CV"
  },
  "Challenging Negative Thoughts Worksheet": {
    "en": "Challenging Negative Thoughts Worksheet",
    "nl": "Werkblad voor uitdagende negatieve gedachten",
    "fr": "Fiche de travail sur les pensées négatives",
    "el": "Φύλλο εργασίας για την πρόκληση αρνητικών σκέψεων",
    "it": "Scheda di lavoro sulle sfide dei pensieri negativi",
    "pt": "Folha de Trabalho para Desafiar Pensamentos Negativos",
    "ro": "Foaie de lucru pentru contestarea gândurilor negative",
    "de": "Arbeitsblatt für das Herausfordern negativer Gedanken",
    "es": "Hoja de trabajo para desafiar pensamientos negativos"
  },
  "Changing the Jackal": {
    "en": "Changing the Jackal",
    "nl": "De jakhals veranderen",
    "fr": "Changer le chacal",
    "el": "Αλλαγή του τσακαλιού",
    "it": "Cambiare il Jackal",
    "pt": "Mudando o Chacal",
    "ro": "Schimbarea lui Jackal",
    "de": "Den Schakal ändern",
    "es": "Cambiando el chacal"
  },
  "Cognitive Restructuring Practice": {
    "en": "Cognitive Restructuring Practice",
    "nl": "Cognitieve herstructureringsoefening",
    "fr": "Pratique de la restructuration cognitive",
    "el": "Πρακτική γνωστικής αναδόμησης",
    "it": "Pratica di ristrutturazione cognitiva",
    "pt": "Prática de Reestruturação Cognitiva",
    "ro": "Practică de restructurare cognitivă",
    "de": "Praxis der kognitiven Umstrukturierung",
    "es": "Práctica de reestructuración cognitiva"
  },
  "Coping Thoughts Worksheet": {
    "en": "Coping Thoughts Worksheet",
    "nl": "Werkblad voor coping-gedachten",
    "fr": "Fiche de travail sur les pensées de coping",
    "el": "Φύλλο εργασίας για σκέψεις προσαρμογής",
    "it": "Scheda di lavoro per i pensieri di coping",
    "pt": "Folha de Trabalho de Pensamentos de Enfrentamento",
    "ro": "Foaie de lucru pentru gânduri de adaptare",
    "de": "Arbeitsblatt für Bewältigungsgedanken",
    "es": "Hoja de trabajo para pensamientos de afrontamiento"
  },
  "Emotion Journal": {
    "en": "Emotion Journal",
    "nl": "Emotiedagboek",
    "fr": "Journal des émotions",
    "el": "Ημερολόγιο συναισθημάτων",
    "it": "Diario delle emozioni",
    "pt": "Diário de Emoções",
    "ro": "Jurnal de emoții",
    "de": "Gefühlstagebuch",
    "es": "Diario de emociones"
  },
  "Empathy Map": {
    "en": "Empathy Map",
    "nl": "Empathiekaart",
    "fr": "Carte d'empathie",
    "el": "Χάρτης ενσυναίσθησης",
    "it": "Mappa dell'empatia",
    "pt": "Mapa de Empatia",
    "ro": "Hartă de empatie",
    "de": "Empathiekarte",
    "es": "Mapa de empatía"
  },
  "GROW": {
    "en": "GROW",
    "nl": "GROEIEN",
    "fr": "CROÎTRE",
    "el": "ΑΝΑΠΤΥΞΗ",
    "it": "CRESCERE",
    "pt": "CRESCER",
    "ro": "CREȘTERE",
    "de": "WACHSEN",
    "es": "CRECER"
  },
  "Healthy_Unhealthy Relationships - Matching Activity": {
    "en": "Healthy_Unhealthy Relationships - Matching Activity",
    "nl": "Gezonde_Ongezonde relaties - Matchactiviteit",
    "fr": "Relations saines_non saines - Activité d'association",
    "el": "Υγιείς_Μη υγιείς σχέσεις - Δραστηριότητα αντιστοίχισης",
    "it": "Relazioni sane_non sane - Attività di abbinamento",
    "pt": "Relacionamentos Saudáveis_Não Saudáveis - Atividade de Correspondência",
    "ro": "Relații sănătoase_Nesănătoase - Activitate de potrivire",
    "de": "Gesunde_Ungesunde Beziehungen - Zuordnungsaktivität",
    "es": "Relaciones Saludables_No Saludables - Actividad de Emparejamiento"
  },
  "How would you treat a friend": {
    "en": "How would you treat a friend",
    "nl": "Hoe zou je een vriend behandelen",
    "fr": "Comment traiteriez-vous un ami",
    "el": "Πώς θα αντιμετωπίζατε έναν φίλο",
    "it": "Come tratteresti un amico",
    "pt": "Como você trataria um amigo",
    "ro": "Cum ai trata un prieten",
    "de": "Wie würdest du einen Freund behandeln",
    "es": "¿Cómo tratarías a un amigo?"
  },
  "Identifying Triggers": {
    "en": "Identifying Triggers",
    "nl": "Triggers identificeren",
    "fr": "Identifier les déclencheurs",
    "el": "Εντοπισμός εκλυτών",
    "it": "Identificare i trigger",
    "pt": "Identificando Gatilhos",
    "ro": "Identificarea declanșatorilor",
    "de": "Erkennen von Auslösern",
    "es": "Identificando desencadenantes"
  },
  "Letter to Future Me": {
    "en": "Letter to Future Me",
    "nl": "Brief aan toekomstige ik",
    "fr": "Lettre à mon futur moi",
    "el": "Γράμμα στον μελλοντικό μου εαυτό",
    "it": "Lettera al me futuro",
    "pt": "Carta para o Futuro Eu",
    "ro": "Scrisoare către viitorul meu",
    "de": "Brief an mein zukünftiges Ich",
    "es": "Carta para mi futuro yo"
  },
  "List of Coping Skills": {
    "en": "List of Coping Skills",
    "nl": "Lijst van copingvaardigheden",
    "fr": "Liste des compétences d'adaptation",
    "el": "Λίστα δεξιοτήτων αντιμετώπισης",
    "it": "Elenco delle abilità di coping",
    "pt": "Lista de Habilidades de Enfrentamento",
    "ro": "Listă de abilități de adaptare",
    "de": "Liste der Bewältigungsfähigkeiten",
    "es": "Lista de habilidades de afrontamiento"
  },
  "Mindful Communication": {
    "en": "Mindful Communication",
    "nl": "Bewuste communicatie",
    "fr": "Communication consciente",
    "el": "Ενσυνείδητη επικοινωνία",
    "it": "Comunicazione consapevole",
    "pt": "Comunicação Consciente",
    "ro": "Comunicare conștientă",
    "de": "Achtsame Kommunikation",
    "es": "Comunicación consciente"
  },
  "Mock Interview": {
    "en": "Mock Interview",
    "nl": "Simulatie-interview",
    "fr": "Entretien simulé",
    "el": "Εικονική συνέντευξη",
    "it": "Intervista simulata",
    "pt": "Entrevista Simulada",
    "ro": "Interviu simulat",
    "de": "Probeinterview",
    "es": "Entrevista simulada"
  },
  "My Safe Place": {
    "en": "My Safe Place",
    "nl": "Mijn veilige plek",
    "fr": "Mon endroit sûr",
    "el": "Το ασφαλές μέρος μου",
    "it": "Il mio posto sicuro",
    "pt": "Meu Lugar Seguro",
    "ro": "Locul meu sigur",
    "de": "Mein sicherer Ort",
    "es": "Mi lugar seguro"
  },
  "Pick a Treat, Tell a Story": {
    "en": "Pick a Treat, Tell a Story",
    "nl": "Kies een traktatie, vertel een verhaal",
    "fr": "Choisissez une friandise, racontez une histoire",
    "el": "Διαλέξτε μια απόλαυση, πείτε μια ιστορία",
    "it": "Scegli un dolcetto, racconta una storia",
    "pt": "Escolha um Agrado, Conte uma História",
    "ro": "Alege o recompensă, spune o poveste",
    "de": "Wähle eine Belohnung, erzähle eine Geschichte",
    "es": "Elige un dulce, cuenta una historia"
  },
  "Plan of Action": {
    "en": "Plan of Action",
    "nl": "Actieplan",
    "fr": "Plan d'action",
    "el": "Σχέδιο δράσης",
    "it": "Piano d'azione",
    "pt": "Plano de Ação",
    "ro": "Plan de acțiune",
    "de": "Aktionsplan",
    "es": "Plan de acción"
  },
  "Positive Pathways Collage": {
    "en": "Positive Pathways Collage",
    "nl": "Positieve paden collage",
    "fr": "Collage des chemins positifs",
    "el": "Κολάζ θετικών διαδρομών",
    "it": "Collage di percorsi positivi",
    "pt": "Colagem de Caminhos Positivos",
    "ro": "Colaj de căi pozitive",
    "de": "Collage positiver Wege",
    "es": "Collage de caminos positivos"
  },
  "Prevent from Boiling Over": {
    "en": "Prevent from Boiling Over",
    "nl": "Voorkom overkoken",
    "fr": "Prévenir l'ébullition",
    "el": "Πρόληψη από το να ξεχειλίσει",
    "it": "Prevenire dal traboccare",
    "pt": "Prevenir de Transbordar",
    "ro": "Prevenirea de la supraîncălzire",
    "de": "Überkochen verhindern",
    "es": "Prevenir el desbordamiento"
  },
  "Respect": {
    "en": "Respect",
    "nl": "Respect",
    "fr": "Respect",
    "el": "Σεβασμός",
    "it": "Rispetto",
    "pt": "Respeito",
    "ro": "Respect",
    "de": "Respekt",
    "es": "Respeto"
  },
  "Stinky Fish": {
    "en": "Stinky Fish",
    "nl": "Stinkende vis",
    "fr": "Poisson puant",
    "el": "Βρομερό ψάρι",
    "it": "Pesce puzzolente",
    "pt": "Peixe Fedorento",
    "ro": "Pește urât mirositor",
    "de": "Stinkender Fisch",
    "es": "Pez maloliente"
  },
  "The 5-4-3-2-1 Emotional Regulation Technique": {
    "en": "The 5-4-3-2-1 Emotional Regulation Technique",
    "nl": "De 5-4-3-2-1 emotionele regulatietechniek",
    "fr": "La technique de régulation émotionnelle 5-4-3-2-1",
    "el": "Η τεχνική ρύθμισης συναισθημάτων 5-4-3-2-1",
    "it": "La tecnica di regolazione emotiva 5-4-3-2-1",
    "pt": "A Técnica de Regulação Emocional 5-4-3-2-1",
    "ro": "Tehnica de reglare emoțională 5-4-3-2-1",
    "de": "Die 5-4-3-2-1 Technik der Emotionsregulation",
    "es": "La técnica de regulación emocional 5-4-3-2-1"
  },
  "The Detective": {
    "en": "The Detective",
    "nl": "De detective",
    "fr": "Le détective",
    "el": "Ο ντετέκτιβ",
    "it": "Il detective",
    "pt": "O Detetive",
    "ro": "Detectivul",
    "de": "Der Detektiv",
    "es": "El detective"
  },
  "The Endless Cycle": {
    "en": "The Endless Cycle",
    "nl": "De eindeloze cyclus",
    "fr": "Le cycle sans fin",
    "el": "Ο ατελείωτος κύκλος",
    "it": "Il ciclo senza fine",
    "pt": "O Ciclo Sem Fim",
    "ro": "Ciclul fără sfârșit",
    "de": "Der endlose Kreislauf",
    "es": "El ciclo sin fin"
  },
  "The Jackal": {
    "en": "The Jackal",
    "nl": "De jakhals",
    "fr": "Le chacal",
    "el": "Το τσακάλι",
    "it": "Il Jackal",
    "pt": "O Chacal",
    "ro": "Șacalul",
    "de": "Der Schakal",
    "es": "El chacal"
  },
  "The Power of a Story": {
    "en": "The Power of a Story",
    "nl": "De kracht van een verhaal",
    "fr": "Le pouvoir d'une histoire",
    "el": "Η δύναμη μιας ιστορίας",
    "it": "Il potere di una storia",
    "pt": "O Poder de uma História",
    "ro": "Puterea unei povești",
    "de": "Die Macht einer Geschichte",
    "es": "El poder de una historia"
  },
  "Thoughts Matter": {
    "en": "Thoughts Matter",
    "nl": "Gedachten doen ertoe",
    "fr": "Les pensées comptent",
    "el": "Οι σκέψεις μετρούν",
    "it": "I pensieri contano",
    "pt": "Pensamentos Importam",
    "ro": "Gândurile contează",
    "de": "Gedanken zählen",
    "es": "Los pensamientos importan"
  },
  "Through the Scanner": {
    "en": "Through the Scanner",
    "nl": "Door de scanner",
    "fr": "À travers le scanner",
    "el": "Μέσα από το σαρωτή",
    "it": "Attraverso lo scanner",
    "pt": "Através do Scanner",
    "ro": "Prin scaner",
    "de": "Durch den Scanner",
    "es": "A través del escáner"
  },
  "Tree of Life": {
    "en": "Tree of Life",
    "nl": "Boom des levens",
    "fr": "Arbre de vie",
    "el": "Δέντρο της ζωής",
    "it": "Albero della vita",
    "pt": "Árvore da Vida",
    "ro": "Arborele vieții",
    "de": "Baum des Lebens",
    "es": "Árbol de la vida"
  },
  "Unhealthy Relationships - Quiz": {
    "en": "Unhealthy Relationships - Quiz",
    "nl": "Ongezonde relaties - Quiz",
    "fr": "Relations malsaines - Quiz",
    "el": "Μη υγιείς σχέσεις - Κουίζ",
    "it": "Relazioni malsane - Quiz",
    "pt": "Relacionamentos Não Saudáveis - Quiz",
    "ro": "Relații nesănătoase - Quiz",
    "de": "Ungesunde Beziehungen - Quiz",
    "es": "Relaciones no saludables - Quiz"
  },
  "Values": {
    "en": "Values",
    "nl": "Waarden",
    "fr": "Valeurs",
    "el": "Αξίες",
    "it": "Valori",
    "pt": "Valores",
    "ro": "Valori",
    "de": "Werte",
    "es": "Valores"
  },
  "What to do in times of stress": {
    "en": "What to do in times of stress",
    "nl": "Wat te doen in tijden van stress",
    "fr": "Que faire en période de stress",
    "el": "Τι να κάνετε σε περιόδους άγχους",
    "it": "Cosa fare nei momenti di stress",
    "pt": "O que fazer em tempos de estresse",
    "ro": "Ce să faci în perioadele de stres",
    "de": "Was in Zeiten von Stress zu tun ist",
    "es": "Qué hacer en momentos de estrés"
  },
  "Wheel of Emotions": {
    "en": "Wheel of Emotions",
    "nl": "Emotiewiel",
    "fr": "Roue des émotions",
    "el": "Τροχός των συναισθημάτων",
    "it": "Ruota delle emozioni",
    "pt": "Roda das Emoções",
    "ro": "Roata emoțiilor",
    "de": "Rad der Emotionen",
    "es": "Rueda de las emociones"
  }

};

export const useResourceTranslate = () => {
  const { lang } = useLang();
  return (label: keyof typeof MentorResourceLang | keyof typeof MenteeResourceLang) => {
    if (lang === "en") {
      return label;
    }
    if (MentorResourceLang[label as keyof typeof MentorResourceLang]) {
      return (MentorResourceLang as any)[label][lang] || label;
    }
    if (MenteeResourceLang[label as keyof typeof MenteeResourceLang]) {
      return (MenteeResourceLang as any)[label][lang] || label;
    }

    return label;
  }
};
