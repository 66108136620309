import React from 'react'
import Button from './Button'
import Fa from './FontAwesome'
import { useNavigate } from 'react-router-dom';
import { Labels } from '../common/lang';
import Lang from './Lang';

const Back = ({ children = <></> as React.ReactNode, small = false, text = "" as Labels, link = -1 as string | -1 }) => {
    const navigate = useNavigate();
    if (text) return (
        <Button onClick={e => [e.preventDefault(), navigate(link as string)]}>
            <Fa duotone="circle-arrow-left fa-xl" /> <Lang text={text} />
        </Button>
    )
    return (
        <Button color="" width={`w-auto ${small && 'm-0 py-0'}`} onClick={e => [e.preventDefault(), navigate(link as string)]}>
            <Fa duotone="circle-arrow-left fa-xl" /> {children}
        </Button>
    )
}

export default Back