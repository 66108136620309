import React from 'react'
import Title from '../Title'
import { useTranslate } from '../../common/lang'

const ChildProtectionPolicy = () => {
    const translate = useTranslate();
    return (
        <div>
            <Title text='Politica de Protecție a Copilului' />
            <h6>{translate("1. Cine este un Copil?")}</h6>
            <p>
                {translate("Conform Convenției cu privire la Drepturile Copilului (1989), considerăm orice persoană sub vârsta de 18 ani un copil. Acest lucru înseamnă că au anumite drepturi specifice alături de cele de care se bucură ceilalți membri ai societății, deoarece se află în proces de dezvoltare.")}
            </p>
            <p>
                {translate("Adulții trebuie să respecte maturitatea și capacitatea lor de a negocia situații care variază în funcție de faza de creștere. Aceștia ar trebui să beneficieze de un mediu sigur și protector în care să primească îngrijirea necesară pentru a se dezvolta fizic, cognitiv și emoțional. Neglijarea acestor nevoi este înțeleasă ca o formă de violență împotriva copiilor.")}
            </p>
            <h6>{translate("2. Ce este Abuzul Asupra Copiilor?")}</h6>
            <p>
                {translate("Abuzul asupra copiilor este orice formă de abuz fizic, emoțional sau sexual și include exploatarea copiilor, comercială sau nu. Abandonul și neglijarea unui copil care rezultă în daune reale sau potențiale împotriva unui copil pot fi, de asemenea, considerate forme de abuz. Abuzul poate fi deliberat sau rezultatul lipsei de acțiune preventivă și de protecție. Noi, ca Parteneriat Mentor+, condamnăm ferm toate formele de abuz asupra copiilor și exploatarea copiilor. Pentru a proteja copiii în toate activitățile noastre și pentru a le garanta respectul și protecția fizică și emoțională, se așteaptă ca mentorii să raporteze orice caz de abuz asupra copiilor coordonatorului direct.")}
            </p>
            <h6>{translate("3. Codul de conduită pentru mentori")}</h6>
            <p>
                {translate("Partenerii Mentor+ apreciază foarte mult principiul conform căruia copiii și tinerii pot beneficia nemăsurat de relațiile de sprijin cu adulții care îi pot ghida și ajuta. Pentru ca acest principiu să funcționeze fără probleme, parteneriatul consideră codul de conduită prezentat mai jos extrem de important. Acestea ar trebui să fie digerate cu faptul că, în special, comportamentul mentorilor poate fi supus controlului și că se așteaptă ca aceștia să fie modele de bune practici în ceea ce privește lucrul cu copiii și tinerii și să nu se implice în niciunul dintre următoarele comportamente:")}
            </p>
            <ul>
                <li>
                    {translate("Hărțuirea, hărțuirea sau discriminarea oricărui copil (chiar și în moduri subtile, cum ar fi atragerea atenției asupra diferențelor personale sau fizice).")}
                </li>
                <li>
                    {translate("Înjosirea sau rușinerea unui copil care se luptă sau căruia îi este dificilă munca.")}
                </li>
                <li>
                    {translate("Lovirea, plesnirea sau cauzarea de vătămări fizice unui copil ca o ieșire pentru frustrare sau ca mijloc de disciplină.")}
                </li>
                <li>
                    {translate("Interacționând cu copiii într-un mod nepotrivit, evidențiind-i pentru laudă sau atenție sau încercând să-i umilești sau să-i facă să se simtă inconfortabil.")}
                </li>
                <li>
                    {translate("Intrarea într-o relație intimă sau sexuală cu un copil sau utilizarea limbajului sexual în jurul acestuia, inclusiv comentarii sau conversații sugestive.")}
                </li>
                <li>
                    {translate("Intrarea într-o relație cu un mentorat în afara proiectului.")}
                </li>
                <li>
                    {translate("Interacțiunea individuală cu mentorați pe rețelele sociale, cum ar fi WhatsApp, Snapchat, Instagram sau alt serviciu de rețele sociale, serviciu de mesagerie sau joc. Acceptarea invitațiilor prietenilor sau împărtășirea numerelor personale cu oricare dintre copiii din proiect.")}
                </li>
                <li>
                    {translate("Faceți fotografii copiilor sau distribuiți fotografii cu dvs.")}
                </li>
                <li>
                    {translate("Oferirea de cadouri, privilegii sau recompense unui copil pentru a construi o relație specială cu el. Asumarea sarcinilor de mentorat în timp ce se află sub influența alcoolului sau a drogurilor.")}
                </li>
            </ul>
        </div>
    )
}

export default ChildProtectionPolicy