import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { UserEntity } from '../../../../backend/src/shared/entity';
import FaRole from '../../components/Icons/FaRole';
import { UserRole } from '../../common/user';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import Back from '../../components/Back';
import Button from '../../components/Button';

const CoordinatorTeamMembers = () => {
    const {user} = useUser();
    const { data: users } = useQuery({
        queryKey: ['coordinatorTeamQuery'],
        queryFn: () => sql.coordinatorTeamQuery({idUser:user.account?.Id}) as Promise<Array<UserEntity>>,
        enabled: !!user.account?.Id
    });
    return (
        <ul className='nav flex-column'>
            <li>
                <Back small/>
            </li>
            {users?.map(u => (
                <li key={u.Id}>
                    <Link to={`/coordinator/team-rooms/${u.Id}/${u.Role}/${u.Name}`} className='nav-link'>
                        <FaRole role={u.Role as UserRole} /> &nbsp; {u.Name}
                    </Link>

                </li>
            ))}
            <li>
                <Button to='/coordinator/mentoring-relationship' color='btn-outline-primary' text='Adăugare relație de mentorat'/>
            </li>
        </ul>
    )
}

export default CoordinatorTeamMembers