import React from 'react'
import { PartnerEntity } from '../../../../backend/src/shared/entity'
import { useQuery } from '@tanstack/react-query'
import sql from '../../common/sql'
import Loading from '../Loading/Loading'
import { Link } from 'react-router-dom'
import Title from '../Title'

const Partnership = () => {

    const partners = useQuery({
        queryKey: ['getPartnersQuery'],
        queryFn: () => sql.getPartnersQuery() as Promise<Array<PartnerEntity>>
    });

    if (partners.isLoading) return <Loading />;

    return (
        <div>
            <Title text='Parteneriat' />
            <div className="row">
                {
                    partners.data?.map((partner, i) => (
                        // <div key={i} className='d-flex justify-content-between'>
                        //     <img src={`/images/partners/${partner.Logo}`} className='img-fluid' alt="Partner logo" />
                        //     <div>
                        //         <b>{partner.Name}</b> <br />
                        //         <span className='text-muted'>{partner.Country}</span> <br />
                        //         <Link to={partner.Website} target='_blank'>{partner.Website}</Link> 
                        //     </div>
                        // </div>
                        <div key={i} className="col-xl-3 pb-4">
                            <div className="card h-100">
                                <img src={`/images/partners/${partner.Logo}`} className="card-img-top" alt="Partner logo" />
                                <div className="card-body">
                                    <h6 className="card-title">{partner.Name}</h6>
                                    <p className="card-text">{partner.Country}</p>
                                    <Link to={partner.Website} target='_blank'>{partner.Website}</Link>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Partnership