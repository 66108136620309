import React from 'react'
import { MentoringSessionEntity } from '../../../../backend/src/shared/entity'
import { localeDateTime } from '../../common/utils'
import Fa from '../FontAwesome'
import { useTranslate } from '../../common/lang'
import Lang from '../Lang'
import { useUser } from '../../hooks/useUser'

const MentoringSessionNext = ({ nextSession = {} as MentoringSessionEntity }) => {
    const translate = useTranslate();
    const { user } = useUser();
    const awaitingText = translate('Aștept confirmare de la') + " " + (user.role === 'Mentor' ? nextSession.Mentee : nextSession.Mentor);
    const isAwaiting = user.role === 'Mentorat' && !nextSession.MentorAccepted || user.role === 'Mentor' && !nextSession.MenteeAccepted;
    return (
        <>
            {translate("Următoarea sesiune va fi pe")} : <b>{nextSession.DateProposal && localeDateTime(nextSession.DateProposal)}</b>
            {isAwaiting && <p><Fa duotone='question-circle text-danger fa-lg' /> (<small className='fst-italic'>{awaitingText}</small>)</p>}
            {nextSession.MenteeAccepted && nextSession.MentorAccepted && <p className='fst-italic my-1'><Fa duotone='check-circle text-success fa-lg' /><small> {translate("întâlnire agreată")} </small></p>}
            <div className="row fw-bold">
                {nextSession && nextSession.Location === 1 && <a href={nextSession.LinkOrAddress} target='_blank' rel='noreferrer'><Fa regular='link fa-fw' /><Lang text="Link-ul sesiunii online" /></a>}
                {nextSession && nextSession.Location === 2 && <span><Fa regular='location-dot fa-fw' /> {nextSession.LinkOrAddress}</span>}
            </div>
        </>
    )
}

export default MentoringSessionNext