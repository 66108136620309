// src/components/PDFViewer.js

import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useParams } from 'react-router-dom';
import Title from './Title';
import { Labels } from '../common/lang';

const PDFViewer = ({ file = "" }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const workerUrl = `https://unpkg.com/pdfjs-dist@${require('pdfjs-dist/package.json').version}/build/pdf.worker.min.js`;
    const { fileUrl = file, fileName } = useParams();
    return (
        <div style={{ height: '750px' }}>
            {fileName && <Title text={(fileName as Labels) || "Pagina anterioară"} />}
            <Worker workerUrl={workerUrl}>
                <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
        </div>
    );
};

export default PDFViewer;
