import React from 'react'
import { useUser } from '../../hooks/useUser'
import Loading from '../Loading/Loading';
import MentorSessions from './MentorSessions';
import MenteeSessions from './MenteeSessions';
import CoordinatorSessions from './CoordinatorSessions';
import Account from '../Account';

const Sessions = () => {
    const { user } = useUser();
    if (!user.account?.Id) return <Loading />
    if (user.role === 'Mentor') return <MentorSessions />
    if (user.role === 'Mentorat') return <MenteeSessions />
    if (user.role === 'Coordonator') return <CoordinatorSessions />
    return <Account />
}

export default Sessions
