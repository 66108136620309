import React from 'react';

const CalendarPicker = ({ date = new Date(), setDate = (date: Date) => { }, dateOnly = false, minDate = null as Date | null, maxDate = null as Date | null, required = false }) => {
    // Obține ora locală
    const localDate = new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000);
    return (
        <div className="container">
            <div className="row">
                <div className={`col ${dateOnly ? 'ps-0' : 'p-0'}`}>
                    <input
                        type="date"
                        className="form-control"
                        value={localDate.toISOString().split('T')[0]}
                        min={minDate?.toISOString().split('T')[0]}
                        max={maxDate?.toISOString().split('T')[0]}
                        required={required}
                        onChange={e => setDate(new Date(e.target.value + 'T' + localDate.toISOString().split('T')[1]))}
                    />
                </div>
                {dateOnly || <div className="col pe-0">
                    <input
                        type="time"
                        className="form-control"
                        value={localDate.toISOString().split('T')[1].slice(0, 5)}
                        onChange={e => setDate(new Date(localDate.toISOString().split('T')[0] + 'T' + e.target.value + ':00'))}
                    />
                </div>}
            </div>
        </div>
    );
}

export default CalendarPicker;
