import { useLocation } from "react-router-dom";
import { useWebSocket } from "./useWebSocket";
import React, { createContext, useContext, useEffect } from "react";
import { useUser } from "./useUser";

const MessagesContext = createContext<{ messages: { [key: string]: number }, messagesCount: number }>(null as any);

export const MessagesProvider = ({ children = null as React.ReactNode }) => {
    const { user } = useUser();
    const location = useLocation();
    const socket = useWebSocket();
    const [messages, setMessages] = React.useState<{ [key: string]: number }>({});

    const onMessage = (event: MessageEvent) => {
        const [from] = event.data.split(':');
        const url = window.location.pathname;
        if (from.length !== user.uuid?.length) return;
        if (url.startsWith('/chat') && url.includes(from)) return;
        setMessages(prev => ({ ...prev, [from]: (prev[from] || 0) + 1 }));
    }
    useEffect(() => {
        socket.addEventListener('message', onMessage);
        return () => socket.removeEventListener('message', onMessage);
    }, [socket]);
    useEffect(() => {
        const url = window.location.pathname;
        const uuid = url.split('/').pop();
        if (uuid && url.startsWith('/chat') && url.includes(uuid) && messages[uuid]) {
            setMessages(prev => ({ ...prev, [uuid]: 0 }));
        }
    }, [location]);

    return (
        <MessagesContext.Provider value={{ messages: messages, messagesCount: Object.values(messages).reduce((a, b) => a + b, 0) }}>
            {children}
        </MessagesContext.Provider>
    );
}

const useMessages = () => {
    return useContext(MessagesContext);
}

export default useMessages;