import React from 'react'
import Title from '../Title'
import { Labels, useTranslate } from '../../common/lang'

const MentorPlus = () => {
    const translate = useTranslate();
    return (
        <div>
            <Title text={"Mentor+" as Labels} />
            <body className="container mt-4">
                <h3>{translate("Proiectul Mentor+")}</h3>
                <p>
                    {translate("Proiectul Mentor+ oferă o intervenție de educație non-formală pentru a angaja, conecta și împuternici tinerii aflați în conflict cu legea. Mai precis, proiectul creează un model de mentorat care folosește adulți modele de comportament respectuosi de lege pentru a sprijini tinerii aflați în conflict cu legea în procesul de dezvoltare a unor atitudini și comportamente pozitive. Proiectul își propune să realizeze acest lucru prin trei oferte:")}
                </p>
                <p>
                    {translate("Designarea și pilotarea unui program de mentorat pentru tinerii implicați în justiție pentru a evita incarcerarea/instituționalizarea acestora, vizând cel puțin 60 de tineri în conflict cu legea din 6 state membre.")}
                </p>
                <p>
                    {translate("Crearea unui ghid pentru mentorii pentru a-i sprijini în mentoratul tinerilor implicați în justiție (oferind ghiduri, șabloane și instrumente utile pentru a fi utilizate în timpul sesiunilor de mentorat).")}
                </p>
                <p>
                    {translate("Crearea și pilotarea unei aplicații mobile, disponibilă în 6 limbi europene diferite, pentru a sprijini mentori și mentorii în timp real, dând vizibilitate valorii adăugate a naturii electronice a e-mentoratului ca mecanism care permite relațiilor de mentorat să depășească limitele geografice și temporale.")}
                </p>
                <h3>{translate("Tineretul din cadrul Sistemului de Justiție Juvenil")}</h3>
                <p>
                    {translate("Tineretul din cadrul sistemului de justiție juvenilă (JJS) sunt printre cei mai vulnerabili cetățeni. Cercetările arată că încarcerarea nu răspunde nevoilor lor și adesea rezultă în consecințe negative asupra comportamentului și sănătății mintale (de exemplu, menținerea sau chiar creșterea nivelurilor de implicare în comportamente antisociale și activități criminale) (Lambie & Randell, 2013). Tineretul din JJS continuă să experimenteze încălcări de rutină ale drepturilor lor de bază, inclusiv violență și izolare în centrele de detenție (Souverein et al., 2019). În plus, relația dintre delincvența juvenilă și abandonul școlar, problemele de sănătate mintală, abuzul de substanțe, șomajul, stilul de viață ilegal, un risc mai mare de perpetuare intergenerațională a tiparelor și tulburările internalizate (de exemplu, depresia și anxietatea) este susținută de cercetări.")}
                </p>
                <h3>{translate("De ce mentoratul ca metodologie?")}</h3>
                <p>
                    {translate("Lipsey et al.’s “Improving the Effectiveness of Juvenile Justice Programs” reflectă rezultatele meta-analizelor privind eficacitatea programelor de reabilitare a infractorilor juvenili (2010, p.12). În consecință:")}
                </p>
                <p>
                    {translate("Pentru infractorii juvenili în general, aparatul de supraveghere al justiției juvenile de probațiune și monitorizare judiciară, casele de grup, facilitățile corecționale și altele asemenea au, în cel mai bun caz, doar efecte favorabile modeste asupra recidivei ulterioare și unele dovezi arată efecte negative modeste (Lipsey și Cullen, 2007; Petrosino, Turpin-Petrosino, și Guckenburg, 2010).")}
                </p>
                <p>
                    {translate("Programmele orientate spre descurajare care se concentrează pe disciplină, supraveghere sau amenințarea cu consecințe punitive (de exemplu, programele de vizitare a închisorii de tip Scared Straight, taberele de antrenament și supravegherea intensivă a libertății condiționate) în medie nu au niciun efect asupra recidivei și pot să o crească (Lipsey, 2009).")}
                </p>
                <p>
                    {translate("Multe programe „terapeutice” orientate spre facilitarea schimbării constructive a comportamentului au arătat efecte foarte pozitive, chiar și pentru infractorii serioși (Lipsey, 2009; Lipsey și Cullen, 2007; Lipsey și Wilson, 1998).")}
                </p>
                <p>
                    {translate("În același timp, meta-analiza cuprinzătoare a lui Lipsey privind efectele intervențiilor în domeniul delincvenței a creat o bază de date cuprinzătoare care includea 548 de studii efectuate între 1958 și 2002. După cum puteți vedea mai jos în Figura 1, abordările punitive au de fapt un impact negativ asupra recidivei grupurilor țintă, în timp ce abordările terapeutice pot fi clar observate că contribuie consecvent la recidivă și dezvoltare pozitivă (Lipsey, 2009, p.24).")}
                </p>

                <div className="col-xl text-center">
                    <p className="text-muted">
                        <i>{translate("Figura 1. Efectul mediu al recidivei pentru categoriile de programe")}</i>
                    </p>
                    <img src="/images/Figure-1.png" className="img-fluid" alt="" />
                </div>

                <p className="mt-5">
                    {translate("Consecințele abordărilor terapeutice fiind spuse, categoria „Consiliere” poate fi considerată cea mai eficientă printre celelalte. O analiză mai profundă în cadrul acestei categorii arată o diferențiere chiar mai mare între diferitele metodologii care aduc schimbări în viața tinerilor. Printre diferitele tipuri de consiliere, așa cum se poate vedea în Figura 2 de mai jos, „consilierea de grup” și „mentoratul” sunt cele două metodologii cele mai promițătoare (Lipsey, 2009, p.25).")}
                </p>
                <div className="col-xl text-center">
                    <p className="text-muted">
                        <i>{translate("Figura 2. Efectele medii de recidivă pentru diferite metodologii din categoria de consiliere")}</i>
                    </p>
                    <img src="/images/Figure-2.png" className="img-fluid" alt="" />
                </div>

                <h3 className="mt-5">{translate("Resurse")}</h3>
                {/* de aici nu mai trebuie traducere */}
                <ul>
                    <li>
                        Lipsey et al. offenders (2010). Improving the Effectiveness of Juvenile Justice Programs. Center for Juvenile Justice Reform.
                    </li>
                    <li>
                        Souverein, F. et al. (2019). Overview of European forensic youth care:
                        Towards an integrative mission for prevention and intervention
                        strategies for juvenile offenders. Child and Adolescent Psychiatry and
                        Mental Health, 13(1), 6. <a href="https://doi.org/10.1186/s13034-019-0265-4">https://doi.org/10.1186/s13034-019-0265-4</a>.
                    </li>
                    <li>
                        Lambie, I., and Randell, I. (2013) . The impact of incarceration on
                        juvenile offenders. Clinical Psychology Review, Volume 33, Issue 3,
                        448-459, ISSN 0272-7358, <a href="https://doi.org/10.1016/j.cpr.2013.01.007">https://doi.org/10.1016/j.cpr.2013.01.007</a> .
                    </li>
                    <li>
                        Lipsey, M . W ., and Cullen, F . T . (2007) . The effectiveness of
                        correctional rehabilitation: A review of systematic reviews . Annual
                        Review of Law and Social Science, 3, 297–320 .
                    </li>
                    <li>
                        Petrosino, A ., Turpin-Petrosino, C ., and Guckenburg, S . (2010) .
                        Formal system processing of juveniles: Effects on delinquency . Campbell
                        Systematic Reviews (www . campbellcollaboration .org) .
                    </li>
                    <li>
                        Lipsey, M . W . (2009) . The primary factors that characterize effective
                        interventions with juvenile offenders: A meta- analytic overview .
                        Victims and Offenders, 4, 124–47 .
                    </li>
                    <li>
                        Lipsey, M . W ., and Wilson, D . B . (1998) . Effective intervention for
                        serious juvenile offenders: A synthesis of research . In R. Loeber and D
                        . P . Farrington (Eds .), Serious and violent juvenile offenders: Risk
                        factors and successful interventions (pp . 313–45). Thousand Oaks, CA:
                        Sage Publications, Inc.
                    </li>
                </ul>
            </body>
        </div>
    )
}

export default MentorPlus