import React from 'react'
import { Labels, useLabels, useTranslate } from '../common/lang';
import PageTitle from '../components/PageTitle';
import { useUser } from '../hooks/useUser';
import { Link } from 'react-router-dom';
import Motto from '../components/Motto';
import Button from '../components/Button';

const HomePage = () => {
  const translate = useTranslate();
  const pageTitle = translate("Acasă");
  PageTitle(pageTitle);
  const { user } = useUser();
  if (!user.email) return <LoginOrRegister />
  return (
    <div className='row'>
      <Motto/>
      <HomePageOption label='Despre' />
      <HomePageOption label='Sesiuni' />
      <HomePageOption label='Resurse' />
      <HomePageOption label='Calendar' />
      {/* <HomePageOption label='Forum' /> */}
    </div>
  )
}

export default HomePage

const HomePageOption = ({ label = "" as Labels }) => {
  const translate = useTranslate();
  const labels = useLabels();
  const path = labels[label]['en'].toLowerCase();
  return (
    <article className='col-6'>
      <Link to={`/${path}`} className='d-block'>
        <img src={`/images/home-${path}.png`.toLowerCase()} alt="" className='img img-fluid' />
        <p className='text-center'>{translate(label)}</p>
      </Link>
    </article>
  )
}

const LoginOrRegister = () => {
  const translate = useTranslate();
  return (
    <div className='row'>
      <div className="col-12 px-4">
        <Motto showHeader />
        <Button to='/login'>{translate('Intră în cont')}</Button>
        <Button to='/register' color='btn-outline-warning'>{translate('Înregistrează-te')}</Button>
      </div>
    </div>
  )
}